import { useContext, useMemo, useState } from "react";
import { DeployAllComponent } from "./component";
import { EnvironmentsContext } from "../../../contexts/environments/context";
import {
  StackStatus,
  useAddMyServiceStackMutation,
  useDeployMyCdnStackMutation,
  useDeployMyEcsFargateStackMutation,
  useDeployMyEcsWsFargateStackMutation,
  useGetMyCdnServiceStackStatusQuery,
  useGetMyEcsServiceStackStatusQuery,
  useGetMyEcsWsServiceStackStatusQuery,
  useGetMyGitConnectionsQuery,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";
import { ServiceType, serviceTypeToServiceName } from "../service_types";

export const DeployAllContainer = (props: {
  deploymentId: string;
  serviceType: ServiceType;
  navigateToHelp: (stepId: string) => void;
  envFeaturesStackStatus: StackStatus | null;
  updateEnvFeaturesStack: (
    deploymentId: string,
    fullDomainName: string,
    emailNotificationList: string,
    logRetentionDays: number,
    restApiV1Enabled: boolean,
    restApiV1Subdomain: string,
    httpApiV2Enabled: boolean,
    httpApiV2Subdomain: string,
    vpcEnabled: boolean,
    vpcCidr: string,
    primaryPrivateCidr: string,
    secondaryPrivateCidr: string,
    primaryPublicCidr: string,
    secondaryPublicCidr: string,
    cognitoEnabled: boolean,
    cognitoUserPoolEnabled: boolean,
    cognitoAllowAdminCreateUserOnly: boolean,
    cognitoAlertsEnabled: boolean,
    cognitoOidcEnabled: boolean,
    cognitoOidcUrl: string,
    cognitoOidcClientId: string,
    cognitoOidcThumbprint: string,
    callback: null | (() => void)
  ) => void;
}) => {
  const {
    deploymentId,
    serviceType,
    envFeaturesStackStatus,
    updateEnvFeaturesStack,
  } = props;
  const { loading: envLoading } = useContext(EnvironmentsContext);
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const { data: getMyGitConnectionsData, loading: getMyGitConnectionsLoading } =
    useGetMyGitConnectionsQuery({
      client: bgwService.getClient(),
      variables: {
        deploymentId,
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const envParams = useMemo(
    () => JSON.parse(envFeaturesStackStatus?.parametersJSON || "{}"),
    [envFeaturesStackStatus]
  );

  const fullDomainName = envParams.FullDomainName || "";
  const emailNotificationList =
    envParams.EmailNotificationList || whoAmIData?.whoAmI?.email || "";
  const logRetentionDays = envParams.LogRetentionDays
    ? parseInt(envParams.LogRetentionDays)
    : 365;
  const vpcCidr = envParams.VpcCidr || "";
  const primaryPrivateCidr = envParams.PrimaryPrivateCidr || "";
  const secondaryPrivateCidr = envParams.SecondaryPrivateCidr || "";
  const primaryPublicCidr = envParams.PrimaryPublicCidr || "";
  const secondaryPublicCidr = envParams.SecondaryPublicCidr || "";

  let restApiV1Enabled = envParams.RestApiV1Enabled === "True";
  const restApiV1Subdomain = envParams.RestApiV1Subdomain || "restapi";
  let httpApiV2Enabled = envParams.HttpApiV2Enabled === "True";
  const httpApiV2Subdomain = envParams.HttpApiV2Subdomain || "httpapi";
  let vpcEnabled = envParams.VpcEnabled === "True";
  let cognitoEnabled = envParams.CognitoEnabled === "True";
  const cognitoUserPoolEnabled = envParams.CognitoUserPoolEnabled === "True";
  const cognitoAllowAdminCreateUserOnly =
    envParams.CognitoAllowAdminCreateUserOnly === "True";
  const cognitoAlertsEnabled = envParams.CognitoAlertsEnabled === "True";
  const cognitoOidcEnabled = envParams.CognitoOidcEnabled === "True";
  const cognitoOidcUrl = envParams.CognitoOidcUrl || "";
  const cognitoOidcClientId = envParams.CognitoOidcClientId || "";
  const cognitoOidcThumbprint = envParams.CognitoOidcThumbprint || "";

  if (serviceType === ServiceType.http_server) {
    httpApiV2Enabled = true;
    vpcEnabled = true;
    cognitoEnabled = true;
  } else if (serviceType === ServiceType.http_server_api_key) {
    restApiV1Enabled = true;
    vpcEnabled = true;
  } else if (serviceType === ServiceType.ssr_server) {
    httpApiV2Enabled = true;
    vpcEnabled = true;
  } else if (serviceType === ServiceType.ws_server) {
    vpcEnabled = true;
  } else if (serviceType === ServiceType.static_web) {
    cognitoEnabled = true;
  }

  const pipelineId = serviceTypeToServiceName(serviceType);

  const [mutationInProgress, setMutationInProgress] = useState(false);
  const [deployAllInProgress, setDeployAllInProgress] = useState(false);

  const [addMyServiceStack, { loading: addMyServiceStackLoading }] =
    useAddMyServiceStackMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        setMutationInProgress(false);
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const [deployMyEcsFargateStack, { loading: deployMyEcsFargateStackLoading }] =
    useDeployMyEcsFargateStackMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        setMutationInProgress(false);
        setDeployAllInProgress(false);
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const [
    deployMyEcsWsFargateStack,
    { loading: deployMyEcsWsFargateStackLoading },
  ] = useDeployMyEcsWsFargateStackMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      setMutationInProgress(false);
      setDeployAllInProgress(false);
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [deployMyCdnStack, { loading: deployMyCdnStackLoading }] =
    useDeployMyCdnStackMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        setMutationInProgress(false);
        setDeployAllInProgress(false);
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const {
    data: getMyEcsServiceStackStatusData,
    loading: getMyEcsServiceStackStatusLoading,
    refetch: getMyEcsServiceStackStatusRefetch,
  } = useGetMyEcsServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
      pipelineId,
    },
    pollInterval: 5000,
    onError: (e) => {
      // ignore
    },
  });

  const {
    data: getMyEcsWsServiceStackStatusData,
    loading: getMyEcsWsServiceStackStatusLoading,
    refetch: getMyEcsWsServiceStackStatusRefetch,
  } = useGetMyEcsWsServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
      pipelineId,
    },
    pollInterval: 5000,
    onError: (e) => {
      // ignore
    },
  });

  const {
    data: getMyCdnServiceStackStatusData,
    loading: getMyCdnServiceStackStatusLoading,
    refetch: getMyCdnServiceStackStatusRefetch,
  } = useGetMyCdnServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
      pipelineId,
    },
    pollInterval: 5000,
    onError: (e) => {
      // ignore
    },
  });

  const startDeploy = (
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    dockerfilePath: string | null,
    servicePort: number | null,
    healthCheckPath: string | null,
    buildScriptPath: string | null,
    buildFolderPath: string | null
  ) => {
    if (envFeaturesStackStatus?.status?.includes("IN_PROGRESS")) {
      return;
    }

    if (envFeaturesStackStatus?.status?.includes("CREATE_FAILED")) {
      return;
    }

    if (
      [
        ServiceType.http_server,
        ServiceType.ssr_server,
        ServiceType.ws_server,
        ServiceType.http_server_api_key,
      ].includes(serviceType) &&
      (dockerfilePath === null ||
        servicePort === null ||
        healthCheckPath === null)
    ) {
      return;
    }

    if (
      [ServiceType.static_web].includes(serviceType) &&
      buildScriptPath === null
    ) {
      return;
    }

    setDeployAllInProgress(true);

    updateEnvFeaturesStack(
      deploymentId,
      fullDomainName,
      emailNotificationList,
      logRetentionDays,
      restApiV1Enabled,
      restApiV1Subdomain,
      httpApiV2Enabled,
      httpApiV2Subdomain,
      vpcEnabled,
      vpcCidr,
      primaryPrivateCidr,
      secondaryPrivateCidr,
      primaryPublicCidr,
      secondaryPublicCidr,
      cognitoEnabled, // cognitoEnabled
      cognitoAllowAdminCreateUserOnly, // cognitoAllowAdminCreateUserOnly
      cognitoUserPoolEnabled, // cognitoUserPoolEnabled
      cognitoAlertsEnabled, // cognitoAlertsEnabled
      cognitoOidcEnabled, // cognitoOidcEnabled
      cognitoOidcUrl, // cognitoOidcUrl
      cognitoOidcClientId, // cognitoOidcClientId
      cognitoOidcThumbprint, // cognitoOidcThumbprint
      () => {
        if (serviceType === ServiceType.http_server) {
          setMutationInProgress(true);
          addMyServiceStack({
            variables: {
              stackId: pipelineId,
              type: "ECS",
            },
            onCompleted: () => {
              deployMyEcsFargateStack({
                variables: {
                  stackDeploymentInput: {
                    id: pipelineId,
                    deploymentId,
                    instanceCount: 0,
                    gitConnectionArn,
                    gitRepositoryId,
                    gitBranch,
                    buildspecPath: "",
                    dockerfilePath,
                    runtimeJson: "{}",
                    buildtimeJson: "{}",
                    ecsTaskCpu: 256,
                    ecsTaskRam: 512,
                    servicePort: servicePort!,
                    healthCheckPath: healthCheckPath!,
                    healthCheckPort: servicePort!,
                    logRetentionDays: 365,
                    alarmsEnabled: false,
                    apiResourceEnabled: false,
                    apiV2ResourceEnabled: true,
                    apiV2WsResourceEnabled: false,
                    apiKeyRequired: false,
                    iamAuthRequired: true,
                    cicdManualApproval: false,
                    mapToRootPath: false,
                    stickySessionEnabled: false,
                    stickySessionType: "lb_cookie",
                    autoscaleEnabled: false,
                    maxInstanceCount: 0,
                  },
                },
                onCompleted: async () => {
                  await getMyEcsServiceStackStatusRefetch();
                  setMutationInProgress(false);
                  setDeployAllInProgress(false);
                },
              });
            },
          });
        } else if (serviceType === ServiceType.http_server_api_key) {
          setMutationInProgress(true);
          addMyServiceStack({
            variables: {
              stackId: pipelineId,
              type: "ECS",
            },
            onCompleted: () => {
              deployMyEcsFargateStack({
                variables: {
                  stackDeploymentInput: {
                    id: pipelineId,
                    deploymentId,
                    instanceCount: 0,
                    gitConnectionArn,
                    gitRepositoryId,
                    gitBranch,
                    buildspecPath: "",
                    dockerfilePath,
                    runtimeJson: "{}",
                    buildtimeJson: "{}",
                    ecsTaskCpu: 256,
                    ecsTaskRam: 512,
                    servicePort: servicePort!,
                    healthCheckPath: healthCheckPath!,
                    healthCheckPort: servicePort!,
                    logRetentionDays: 365,
                    alarmsEnabled: false,
                    apiResourceEnabled: true,
                    apiV2ResourceEnabled: false,
                    apiV2WsResourceEnabled: false,
                    apiKeyRequired: true,
                    iamAuthRequired: false,
                    cicdManualApproval: false,
                    mapToRootPath: false,
                    stickySessionEnabled: false,
                    stickySessionType: "lb_cookie",
                    autoscaleEnabled: false,
                    maxInstanceCount: 0,
                  },
                },
                onCompleted: async () => {
                  await getMyEcsServiceStackStatusRefetch();
                  setMutationInProgress(false);
                  setDeployAllInProgress(false);
                },
              });
            },
          });
        } else if (serviceType === ServiceType.ssr_server) {
          setMutationInProgress(true);
          addMyServiceStack({
            variables: {
              stackId: pipelineId,
              type: "ECS",
            },
            onCompleted: () => {
              deployMyEcsFargateStack({
                variables: {
                  stackDeploymentInput: {
                    id: pipelineId,
                    deploymentId,
                    instanceCount: 0,
                    gitConnectionArn,
                    gitRepositoryId,
                    gitBranch,
                    buildspecPath: "",
                    dockerfilePath,
                    runtimeJson: "{}",
                    buildtimeJson: "{}",
                    ecsTaskCpu: 256,
                    ecsTaskRam: 512,
                    servicePort: servicePort!,
                    healthCheckPath: healthCheckPath!,
                    healthCheckPort: servicePort!,
                    logRetentionDays: 365,
                    alarmsEnabled: false,
                    apiResourceEnabled: false,
                    apiV2ResourceEnabled: true,
                    apiV2WsResourceEnabled: false,
                    apiKeyRequired: false,
                    iamAuthRequired: false,
                    cicdManualApproval: false,
                    mapToRootPath: true,
                    stickySessionEnabled: true,
                    stickySessionType: "lb_cookie",
                    autoscaleEnabled: false,
                    maxInstanceCount: 0,
                  },
                },
                onCompleted: async () => {
                  await getMyEcsServiceStackStatusRefetch();
                  setMutationInProgress(false);
                  setDeployAllInProgress(false);
                },
              });
            },
          });
        } else if (serviceType === ServiceType.ws_server) {
          setMutationInProgress(true);
          addMyServiceStack({
            variables: {
              stackId: pipelineId,
              type: "ECS_WS",
            },
            onCompleted: () => {
              deployMyEcsWsFargateStack({
                variables: {
                  stackDeploymentInput: {
                    id: pipelineId,
                    deploymentId,
                    instanceCount: 0,
                    gitConnectionArn,
                    gitRepositoryId,
                    gitBranch,
                    buildspecPath: "",
                    dockerfilePath,
                    runtimeJson: "{}",
                    buildtimeJson: "{}",
                    ecsTaskCpu: 256,
                    ecsTaskRam: 512,
                    servicePort: servicePort!,
                    healthCheckPath: healthCheckPath!,
                    healthCheckPort: servicePort!,
                    logRetentionDays: 365,
                    alarmsEnabled: false,
                    cicdManualApproval: false,
                    stickySessionEnabled: true,
                    stickySessionType: "lb_cookie",
                    autoscaleEnabled: false,
                    maxInstanceCount: 0,
                  },
                },
                onCompleted: async () => {
                  await getMyEcsWsServiceStackStatusRefetch();
                  setMutationInProgress(false);
                  setDeployAllInProgress(false);
                },
              });
            },
          });
        } else if (serviceType === ServiceType.static_web) {
          setMutationInProgress(true);
          addMyServiceStack({
            variables: {
              stackId: pipelineId,
              type: "CDN",
            },
            onCompleted: () => {
              deployMyCdnStack({
                variables: {
                  stackDeploymentInput: {
                    id: pipelineId,
                    deploymentId,
                    gitConnectionArn,
                    gitRepositoryId,
                    gitBranch,
                    buildspecPath: "",
                    buildScriptPath: buildScriptPath!,
                    buildFolderPath: buildFolderPath!,
                    buildtimeJson: "{}",
                    webHostname: "",
                    altFullDomainName: "",
                    altCertificateArn: "",
                  },
                },
                onCompleted: async () => {
                  await getMyCdnServiceStackStatusRefetch();
                  setMutationInProgress(false);
                  setDeployAllInProgress(false);
                },
              });
            },
          });
        }
      }
    );
  };

  return (
    <DeployAllComponent
      loading={
        envLoading ||
        whoAmILoading ||
        getMyGitConnectionsLoading ||
        addMyServiceStackLoading ||
        deployMyEcsFargateStackLoading ||
        deployMyEcsWsFargateStackLoading ||
        deployMyCdnStackLoading ||
        getMyEcsServiceStackStatusLoading ||
        getMyEcsWsServiceStackStatusLoading ||
        getMyCdnServiceStackStatusLoading ||
        mutationInProgress ||
        deployAllInProgress
      }
      deployAllInProgress={deployAllInProgress}
      gitConnections={getMyGitConnectionsData?.getMyGitConnections || []}
      startDeploy={startDeploy}
      envFeaturesStackStatus={envFeaturesStackStatus || null}
      ecsServiceStackStatus={
        getMyEcsServiceStackStatusData?.getMyEcsServiceStackStatus || null
      }
      ecsWsServiceStackStatus={
        getMyEcsWsServiceStackStatusData?.getMyEcsWsServiceStackStatus || null
      }
      cdnServiceStackStatus={
        getMyCdnServiceStackStatusData?.getMyCdnServiceStackStatus || null
      }
      serviceType={serviceType}
    />
  );
};
