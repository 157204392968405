import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceType } from "../service_types";
import { LeanLayout } from "../layout";
import { useContext, useState } from "react";
import { PageLoading } from "../../../components/page_loading";
import { EnvironmentsContext } from "../../../contexts/environments/context";
import Link from "@mui/joy/Link";
import List from "@mui/joy/List";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import SvgIcon from "@mui/joy/SvgIcon";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import { PageTitle } from "../../../components/page_title";
import { PageFrame } from "../../../components/page_frame";
import { useTheme } from "@mui/joy";

export const SelectServiceTypePage = () => {
  const params = useParams();
  const { deploymentId } = params;

  const { loading: envLoading, addDeployment } =
    useContext(EnvironmentsContext);

  const [mutationInProgress, setMutationInProgress] = useState(false);

  const navigate = useNavigate();
  const navigateToLaunch = (serviceType: ServiceType) => {
    if (deploymentId !== undefined) {
      navigate(`/quick_launch/${serviceType}/${deploymentId}`);
    } else {
      setMutationInProgress(true);
      addDeployment("prod", () => {
        setMutationInProgress(false);
        navigate(`/quick_launch/${serviceType}/prod`);
      });
    }
  };

  const navigateToRoot = () => {
    navigate("/");
  };

  const theme = useTheme();

  return (
    <>
      <LeanLayout>
        <PageFrame>
          <PageLoading loading={envLoading || mutationInProgress} />
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid xs={12}>
              <PageTitle>What do you want to deploy?</PageTitle>
            </Grid>
            <Grid xs={12}>
              <List
                size="lg"
                sx={{
                  "--List-item-radius": "8px",
                  "--List-gap": "16px",
                  "--ListItemDecorator-size": "50px",
                  mt: 2,
                  '& [role="button"]': {
                    borderRadius: "5px",
                  },
                }}
              >
                <ListItemButton
                  onClick={() => navigateToLaunch(ServiceType.http_server)}
                >
                  <ListItemDecorator>
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z"
                          fill={theme.vars.palette.neutral[400]}
                        />
                      </svg>
                    </SvgIcon>
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="title-lg">
                      REST API Server for Frontends (no API keys)
                    </Typography>
                    <Typography level="body-xs">
                      Eg: Python FastAPI, NodeJS Express, NodeJS Apollo, Java
                      Spring Boot, etc.
                    </Typography>
                  </ListItemContent>
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    navigateToLaunch(ServiceType.http_server_api_key)
                  }
                >
                  <ListItemDecorator>
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z"
                          fill={theme.vars.palette.neutral[400]}
                        />
                      </svg>
                    </SvgIcon>
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="title-lg">
                      REST API Server with API key support
                    </Typography>
                    <Typography level="body-xs">
                      Eg: Python FastAPI, NodeJS Express, Java Spring Boot, etc.
                    </Typography>
                  </ListItemContent>
                </ListItemButton>

                <ListItemButton
                  onClick={() => navigateToLaunch(ServiceType.ssr_server)}
                >
                  <ListItemDecorator>
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z"
                          fill={theme.vars.palette.neutral[400]}
                        />
                      </svg>
                    </SvgIcon>
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="title-lg">
                      Server-side rendered Web App
                    </Typography>
                    <Typography level="body-xs">
                      Eg: Next.JS SSR, React-Rails, etc.
                    </Typography>
                  </ListItemContent>
                </ListItemButton>

                <ListItemButton
                  onClick={() => navigateToLaunch(ServiceType.static_web)}
                >
                  <ListItemDecorator>
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.447 3.026a.75.75 0 0 1 .527.921l-4.5 16.5a.75.75 0 0 1-1.448-.394l4.5-16.5a.75.75 0 0 1 .921-.527ZM16.72 6.22a.75.75 0 0 1 1.06 0l5.25 5.25a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 1 1-1.06-1.06L21.44 12l-4.72-4.72a.75.75 0 0 1 0-1.06Zm-9.44 0a.75.75 0 0 1 0 1.06L2.56 12l4.72 4.72a.75.75 0 0 1-1.06 1.06L.97 12.53a.75.75 0 0 1 0-1.06l5.25-5.25a.75.75 0 0 1 1.06 0Z"
                          clipRule="evenodd"
                          fill={theme.vars.palette.neutral[400]}
                        />
                      </svg>
                    </SvgIcon>
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="title-lg">Static Web App</Typography>
                    <Typography level="body-xs">
                      Eg: SPA written in React, Vue, Angular, etc.
                    </Typography>
                  </ListItemContent>
                </ListItemButton>

                <ListItemButton
                  onClick={() => navigateToLaunch(ServiceType.ws_server)}
                >
                  <ListItemDecorator>
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z"
                          fill={theme.vars.palette.neutral[400]}
                        />
                      </svg>
                    </SvgIcon>
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="title-lg">WebSocket Server</Typography>
                    <Typography level="body-xs">
                      Eg: Python FastAPI, NodeJS Express, etc.
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </List>
            </Grid>
            <Grid xs={12}>
              <Typography level="body-sm" sx={{ py: 4 }}>
                Looking for other options?{" "}
                <Link onClick={navigateToRoot}>Go to detailed settings</Link>
              </Typography>
            </Grid>
          </Grid>
        </PageFrame>
      </LeanLayout>
    </>
  );
};
