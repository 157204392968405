import Markdown, { ExtraProps } from "react-markdown";
import { RightPaneBackButton } from "../../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../../components/right_pane_frame";

const markdown = `
# Domain Names and Certificates

Waffle's concept is that each environment has its own domain name. Different subdomains are automatically created for the domain: for example HTTP endpoints are created as \`api.my_domain\` by default.

The domain name is either generated by Waffle or you can set up your own domain name. You can start with the Waffle generated domain name, and then switch to your own domain name later as well.

You can add additional domain names to specific services, for example if you want to add a www.my-different-domain.com domain to a frontend web application, that's possible.

In this step of the deployment, either Waffle generates a domain name for you or you can set up your own domain name and Waffle will use that.

The domain name has to be created in your AWS account, using AWS Route 53. This is necessary because Waffle registers dns records for this domain, for example for API gateways or for frontend web applications.
Also, Waffle creates an SSL certificate for the chosen domain name.


## Waffle Generated Domain Name

It's the simpler option. Waffle will generate a domain name for you: \`my_organization-my_environment.app.wafflecode.com\`. You don't need to buy a domain name for this option.

## Custom Domain Name

If you own a domain name, you can use it too. Let's say you have a domain name \`my_domain.com\` registered with a 3rd party domain, then here you can register a subdomain of this domain,
like for example \`my_app.my_domain.com\`. This subdomain will be registered in your AWS account, using AWS Route 53. This case you'll get a list of NS records to this subdomain, that you'll
to register manually whereever your domain name lives.

If you choose this option, Waffle won't be able to start deploying before the NS DNS records are live in your domain name registrar.

`;

const ImageRenderer = (
  props: React.ClassAttributes<HTMLImageElement> &
    React.ImgHTMLAttributes<HTMLImageElement> &
    ExtraProps
) => <img src={props.src} alt={props.alt} style={{ maxWidth: "100%" }} />;

export const FullDomainNamesHelpPane = (props: {
  navigateBack: () => void;
}) => {
  const { navigateBack } = props;
  return (
    <RightPaneFrame>
      <RightPaneBackButton onClick={navigateBack} labelText="Close" />
      <Markdown components={{ img: ImageRenderer }}>{markdown}</Markdown>
    </RightPaneFrame>
  );
};
