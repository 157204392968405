import "@fontsource/share-tech-mono";
import { AspectRatio, Box, Typography } from "@mui/joy";
import { HEADER_HEIGHT } from "../../layout/constants";
// import logo from "./logo.svg";
import logo from "../../../../components/logos/waffle.svg";

export const LogoText = (props: {}) => {
  return (
    <Box
      sx={{
        minHeight: `${HEADER_HEIGHT}px`,
        maxHeight: `${HEADER_HEIGHT}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        // justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <AspectRatio
        objectFit="contain"
        variant="plain"
        ratio={`29/39`}
        sx={{
          // ml: "8px",
          // width: 42,
          // opacity: 0.75,
          mt: 1,
          width: 22,
        }}
      >
        <img src={logo} alt="Waffle" />
      </AspectRatio>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            // fontFamily: "'Share Tech Mono'",
            fontSize: "14px",
            fontWeight: 700,
            ml: "8px",
            // letterSpacing: "0.13em",
            color: "neutral.300",
          }}
        >
          Waffle{" "}
          <Typography
            sx={{
              // fontFamily: "'Share Tech Mono'",
              fontSize: "8px",
              fontWeight: 100,
              ml: "8px",
              // letterSpacing: "0.13em",
              color: "neutral.100",
            }}
          >
            © 2025 All rights reserved.
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
