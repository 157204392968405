import Box from "@mui/joy/Box";
import React, { ReactNode } from "react";
import { Outlet, useOutlet } from "react-router-dom";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  RIGHT_PLANE_WIDTH,
} from "../drawer_layout/layout/constants";

export const PageWithRightPane = (props: { children: ReactNode }) => {
  const { children } = props;
  const outlet = useOutlet();
  return (
    <React.Fragment>
      {outlet ? (
        <Box
          role="button"
          sx={{
            position: "absolute",
            inset: 0,
            bgcolor: "background.backdrop",
            backdropFilter: "blur(3px)",
            zIndex: 400,
            display: {
              xs: "none",
              sm: "inherit",
              md: "none",
            },
          }}
        />
      ) : null}
      <Box
        sx={{
          bgcolor: "neutral.900",
          gridRow: 1,
          gridColumn: {
            xs: 1,
            sm: 2,
          },
        }}
      />
      <Box
        sx={{
          p: 0,
          m: 0,
          pt: {
            xs: `${HEADER_HEIGHT}px`,
            sm: `${HEADER_HEIGHT}px`,
            md: `${HEADER_HEIGHT}px`,
            lg: 0,
          },
          boxSizing: "border-box",
          display: {
            xs: outlet ? "none" : "inherit",
            sm: "inherit",
          },
          gridRow: 1,
          gridColumn: {
            xs: 1,
            sm: "2 / span 2",
            md: outlet ? 2 : "2 / span 2",
          },
          height: {
            xs: `calc(100vh - ${FOOTER_HEIGHT}px)`,
            sm: "100vh",
          },
          overflow: "scroll",
          bgcolor: "neutral.900",
        }}
        aria-label="main-content"
      >
        {children}
      </Box>
      {outlet ? (
        <Box
          sx={[
            {
              p: 0,
              m: 0,
              pt: {
                xs: `${HEADER_HEIGHT}px`,
                sm: `${HEADER_HEIGHT}px`,
                md: `${HEADER_HEIGHT}px`,
                lg: 0,
              },
              boxSizing: "border-box",
              height: {
                xs: `calc(100vh - ${FOOTER_HEIGHT}px)`,
                sm: "100vh",
              },
              position: {
                xs: "inherit",
                sm: "absolute",
                md: "inherit",
              },
              gridRow: 1,
              gridColumn: {
                xs: 1,
                // sm: 3,
                sm: 3,
                md: 3,
              },
              width: {
                xs: "inherit",
                sm: RIGHT_PLANE_WIDTH,
                md: "inherit",
              },
              right: 0,
              overflow: "scroll",
              bgcolor: "neutral.900",
              borderLeft: "1px solid",
              borderLeftColor: "neutral.800",
              zIndex: 500,
            },
          ]}
          aria-label="right-pane"
        >
          <Outlet />
        </Box>
      ) : null}
    </React.Fragment>
  );
};
