import Box from "@mui/joy/Box";
import { ReactNode } from "react";
import { LogoText } from "../../layouts/drawer_layout/drawer/logo_text";
import { Outlet, useOutlet } from "react-router-dom";
import Drawer from "@mui/joy/Drawer";
import DialogContent from "@mui/joy/DialogContent";

export const LeanLayout = (props: { children?: ReactNode }) => {
  const { children } = props;
  const outlet = useOutlet();
  return (
    <Box
      sx={{
        pt: 0,
        px: 0,
        overflow: "hidden",
        display: "grid",
        gridTemplateRows: "auto 120px",
        gridTemplateColumns: {
          xs: "auto",
          sm: "64px auto 64px",
          md: "1fr 1024px 1fr",
        },
      }}
    >
      <Box
        sx={{
          p: 4,
          mt: 4,
          gridRow: 1,
          gridColumn: {
            xs: 1,
            sm: 2,
          },
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          py: 4,
          gridRow: 2,
          gridColumn: {
            xs: 1,
            sm: 2,
          },
          px: 9,
        }}
      >
        <LogoText />
      </Box>
      <Drawer open={outlet !== null} size="md" anchor="right">
        <DialogContent>
          <Outlet />
        </DialogContent>
      </Drawer>
    </Box>
  );
};
