import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import PendingIcon from "@mui/icons-material/Pending";
import { ReactNode } from "react";
import Box from "@mui/joy/Box";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails, {
  accordionDetailsClasses,
} from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import { PageFrame } from "../../components/page_frame";
import { PageLoading } from "../../components/page_loading";
import { PageTitle } from "../../components/page_title";
import { StackStatus } from "../../services/backend_gateway/__generated__/backend_gateway-types";
import ListItemDecorator from "@mui/joy/ListItemDecorator";

const CustomAccordionGroup = (props: { children: ReactNode }) => {
  return (
    <AccordionGroup
      variant="plain"
      transition="0.2s"
      sx={{
        // maxWidth: 400,
        [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
          {
            paddingBlock: "1rem",
          },
        [`& .${accordionSummaryClasses.button}`]: {
          paddingBlock: "1rem",
        },
        // [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
        //   bgcolor: colors.grey2,
        //   borderRadius: "md",
        //   border: "1px solid",
        //   borderColor: "background.level2",
        //   mb: 2,
        // },
        // '& [aria-expanded="true"]': {
        //   boxShadow: (theme) => `inset 0 -1px 0 ${theme.vars.palette.divider}`,
        // },
      }}
      {...props}
    />
  );
};

export const MyEnvironmentComponent = (props: {
  deploymentId: string;
  loading: boolean;
  awsConnectionComponent: ReactNode | null;
  awsConnectionWorks: boolean;
  gitConnectionsComponent: ReactNode | null;
  gitConnectionExists: boolean;
  domainNamesComponent: ReactNode | null;
  domainNamesExists: boolean;
  deployEnvironmentComponent: ReactNode | null;
  editDeploymentName: (name: string) => void;
  envFeaturesStackStatus: StackStatus | null;
  navigateToHelp: (helpId: string) => void;
}) => {
  const {
    deploymentId,
    awsConnectionComponent,
    awsConnectionWorks,
    gitConnectionsComponent,
    gitConnectionExists,
    domainNamesComponent,
    domainNamesExists,
    deployEnvironmentComponent,
    loading,
    // editDeploymentName,
    envFeaturesStackStatus,
  } = props;
  return (
    <PageFrame>
      <Box
        sx={{
          maxWidth: 1024,
          minWidth: 320,
          width: "100%",
          mx: "auto",
        }}
      >
        <PageTitle>{deploymentId} Environment</PageTitle>

        <PageLoading loading={loading} />

        <Box sx={{ borderRadius: 6 }}>
          <CustomAccordionGroup>
            <Accordion disabled={false}>
              <AccordionSummary>
                <ListItemDecorator>
                  {awsConnectionWorks ? (
                    <CheckCircleIcon />
                  ) : (
                    <CircleOutlinedIcon />
                  )}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="h3">AWS Connection</Typography>
                  <Typography level="body-sm">
                    Connect Waffle with your AWS
                  </Typography>
                </ListItemContent>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 2 }}>
                {awsConnectionComponent}
              </AccordionDetails>
            </Accordion>
            <Accordion disabled={!awsConnectionWorks}>
              <AccordionSummary>
                <ListItemDecorator>
                  {gitConnectionExists ? (
                    <CheckCircleIcon />
                  ) : (
                    <CircleOutlinedIcon />
                  )}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="h3">Git Connection</Typography>
                  <Typography level="body-sm">
                    Connect your AWS account with your Git account
                  </Typography>
                </ListItemContent>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 2 }}>
                {awsConnectionWorks && gitConnectionsComponent}
              </AccordionDetails>
            </Accordion>
            <Accordion disabled={!awsConnectionWorks}>
              <AccordionSummary>
                <ListItemDecorator>
                  {domainNamesExists ? (
                    <CheckCircleIcon />
                  ) : (
                    <CircleOutlinedIcon />
                  )}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="h3">Domain name</Typography>
                  <Typography level="body-sm">
                    Register a domain name(s) for this environment
                  </Typography>
                </ListItemContent>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 2 }}>
                {awsConnectionWorks && domainNamesComponent}
              </AccordionDetails>
            </Accordion>
            <Accordion disabled={!awsConnectionWorks || !gitConnectionExists}>
              <AccordionSummary>
                <ListItemDecorator>
                  {envFeaturesStackStatus?.status?.includes("COMPLETE") ? (
                    <CheckCircleIcon />
                  ) : envFeaturesStackStatus?.status?.includes(
                      "IN_PROGRESS"
                    ) ? (
                    <PendingIcon />
                  ) : (
                    <CircleOutlinedIcon />
                  )}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="h3">Environment</Typography>
                  <Typography level="body-sm">
                    Deploy your environment into your AWS account
                  </Typography>
                </ListItemContent>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 2 }}>
                {awsConnectionWorks &&
                  gitConnectionExists &&
                  deployEnvironmentComponent}
              </AccordionDetails>
            </Accordion>
          </CustomAccordionGroup>
        </Box>
      </Box>
    </PageFrame>
  );
};
