import { ReactNode, useContext, useMemo, useRef } from "react";
import {
  GetAllOrganizationsDocument,
  GetMyOrganizationDocument,
  useGetMyOrganizationQuery,
  useSetMyOrganizationMutation,
  useWhoAmIQuery,
  WhoAmIDocument,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { Loading } from "../../pages/loading";
import { matchRoutes, useLocation } from "react-router-dom";
import { AlertsContext } from "../../contexts/alerts/context";
import { AlertType } from "../../contexts/alerts/type";
import { gql } from "@apollo/client";

export const OnboarderContainer = (props: {
  onboardingComponent: ReactNode;
  selectCompanyComponent: ReactNode;
  children: ReactNode;
}) => {
  const { onboardingComponent, children } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const { data: getMyOrganization, loading: getMyOrganizationLoading } =
    useGetMyOrganizationQuery({
      client: bgwService.getClient(),
    });

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
  });
  const iBelongToOrganization = whoAmIData?.whoAmI?.organizationId !== null;

  const iAmOnboarded = useMemo(
    () =>
      whoAmIData?.whoAmI?.role === "ROOT" ||
      getMyOrganization?.getMyOrganization?.onboardingComplete,
    [getMyOrganization, whoAmIData]
  );

  const [setMyOrganization] = useSetMyOrganizationMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const assigningToOrganizationInProgress = useRef(false);

  const location = useLocation();

  const match = matchRoutes(
    [
      { path: "/quick_launch" },
      { path: "/quick_launch/:deploymentId/:serviceType" },
    ],
    location
  );
  if (match !== null) {
    return children;
  }

  if (getMyOrganizationLoading || whoAmILoading) {
    return <Loading />;
  }

  if (!iBelongToOrganization) {
    if (assigningToOrganizationInProgress.current) {
      return <Loading />;
    }
    const organizationName = whoAmIData?.whoAmI?.email || null;
    if (organizationName !== null) {
      const organizationIdArray = organizationName
        .toLocaleLowerCase()
        .match(/[a-z0-9]/g);
      const organizationId = (organizationIdArray || []).join("");
      assigningToOrganizationInProgress.current = true;
      setMyOrganization({
        variables: {
          organizationName,
        },
        optimisticResponse: () => ({
          setMyOrganization: {
            id: organizationId,
            name: organizationName,
            onboardingComplete: false,
            features: {
              singleDeployment: true,
            },
          },
        }),
        update(cache, { data }) {
          cache.modify({
            fields: {
              getMyOrganization() {
                if (data?.setMyOrganization) {
                  const newOrganizationRef = cache.writeFragment({
                    id: `Organization:${data.setMyOrganization.id}`,
                    data: data.setMyOrganization,
                    fragment: gql`
                      fragment OrganizationDetail on Organization {
                        id
                        name
                        onboardingComplete
                        features {
                          singleDeployment
                        }
                      }
                    `,
                  });
                  return newOrganizationRef!;
                }
                return null;
              },
              getAllOrganizations(existingOrganizations = []) {
                const newOrganizationRef = cache.writeFragment({
                  data: data?.setMyOrganization,
                  fragment: gql`
                    fragment NewOrganization on Organization {
                      id
                      name
                    }
                  `,
                });
                return [...existingOrganizations, newOrganizationRef];
              },
              whoAmI(existingWhoAmI) {
                return {
                  ...existingWhoAmI,
                  organizationId: data?.setMyOrganization?.id,
                  role: "ADMIN",
                };
              },
            },
          });
        },
        refetchQueries: [
          {
            query: GetMyOrganizationDocument,
          },
          {
            query: GetAllOrganizationsDocument,
          },
          {
            query: WhoAmIDocument,
          },
        ],
        onCompleted: () => {},
      });
    }
    return <Loading />;
  }

  const hasEnvFeaturesStack =
    getMyOrganization?.getMyOrganization?.features?.envFeaturesStackEnabled;

  if (!iAmOnboarded && hasEnvFeaturesStack && onboardingComponent !== null) {
    return onboardingComponent;
  }

  return children;
};
