import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import {
  Deployment,
  StackStatus,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import {
  ServiceType,
  serviceTypeToLabel,
  serviceTypeToServiceName,
} from "../service_types";
import { useState } from "react";

export const ResultComponent = (props: {
  serviceType: ServiceType;
  envFeaturesStackStatus: StackStatus | null;
  stackStatus: StackStatus | null;
  stackUrl: string | null;
  loading: boolean;
  deployApiGateway: () => void;
  setMyOnboardingComplete: () => void;
  deployment: Deployment | null;
}) => {
  const {
    serviceType,
    // envFeaturesStackStatus,
    stackStatus,
    stackUrl,
    // loading,
    deployApiGateway,
    setMyOnboardingComplete,
    deployment,
  } = props;
  const [apiGatewayDeployed, setApiGatewayDeployed] = useState<boolean>(false);
  return (
    <>
      <Grid container spacing={2} sx={{ flexGrow: 1, my: 2 }}>
        <Grid xs={12}>
          {stackStatus?.status?.includes("COMPLETE") && stackUrl && (
            <>
              <Typography level="body-md">
                Your {serviceTypeToLabel(serviceType)} has been deployed at:
              </Typography>
              <Typography level="body-md">
                <Link href={stackUrl} target="_blank">
                  {stackUrl}
                </Link>
              </Typography>
              {serviceType === ServiceType.http_server_api_key &&
                !apiGatewayDeployed && (
                  <>
                    <Typography level="body-md" sx={{ my: 2 }}>
                      Before your service will be accessible, you need to
                      manually trigger a deployment of your API Gateway. This is
                      how REST API Gateway works.
                    </Typography>
                    <Button
                      onClick={() => {
                        deployApiGateway();
                        setApiGatewayDeployed(true);
                      }}
                    >
                      Deploy API Gateway
                    </Button>
                  </>
                )}
            </>
          )}
        </Grid>
        {/* https://us-east-1.console.aws.amazon.com/codesuite/codepipeline/pipelines/dev-wcafrontend/view?region=us-east-1 */}
        {stackStatus?.status?.includes("COMPLETE") &&
          stackUrl &&
          deployment && (
            <Grid xs={12}>
              <Typography level="body-md">
                The CICD pipeline of your {serviceTypeToLabel(serviceType)} is
                here:
              </Typography>
              <Typography level="body-md">
                <Link
                  href={`https://${
                    deployment.awsRegion
                  }.console.aws.amazon.com/codesuite/codepipeline/pipelines/${
                    deployment.id
                  }-${serviceTypeToServiceName(serviceType)}/view?region=${
                    deployment.awsRegion
                  }`}
                  target="_blank"
                >
                  AWS CodePipeline
                </Link>
              </Typography>
            </Grid>
          )}

        <Grid xs={12}>
          <Button
            // disabled={loading}
            onClick={() => setMyOnboardingComplete()}
            variant={
              !stackStatus?.status?.includes("COMPLETE") ||
              (serviceType === ServiceType.http_server_api_key &&
                !apiGatewayDeployed)
                ? "plain"
                : "solid"
            }
            color={
              !stackStatus?.status?.includes("COMPLETE") ||
              (serviceType === ServiceType.http_server_api_key &&
                !apiGatewayDeployed)
                ? "neutral"
                : "success"
            }
          >
            {!stackStatus?.status?.includes("COMPLETE") ||
            (serviceType === ServiceType.http_server_api_key &&
              !apiGatewayDeployed)
              ? "Skip fast setup"
              : "Done! Go to dashboard"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
