import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Grid from "@mui/joy/Grid";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Textarea from "@mui/joy/Textarea";
import { PageFrame } from "../../components/page_frame";
import { PageLoading } from "../../components/page_loading";
import { PageTitle } from "../../components/page_title";
import { useMemo } from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Chip from "@mui/joy/Chip";
import ListItemContent from "@mui/joy/ListItemContent";

export const MyAnyStackComponent = (props: {
  loading: boolean;
  deploymentIds: string[];
  selectedDeploymentId: string | null;
  setSelectedDeploymentId: (deploymentId: string) => void;
  stackIds: string[];
  selectedStackId: string | null;
  setSelectedStackId: (stackId: string) => void;
  stackTemplate: string;
  setStackTemplate: (stackTemplate: string) => void;
  stackParameters: string;
  setStackParameters: (stackParameters: string) => void;
  stackStatus: string | null;
  stackFullDetails: string | null;
  refreshStackStatus: () => void;
  stackLatestEvents: string | null;
  deployStack: () => void;
}) => {
  const {
    loading,
    deploymentIds,
    selectedDeploymentId,
    setSelectedDeploymentId,
    stackIds,
    selectedStackId,
    setSelectedStackId,
    stackTemplate,
    setStackTemplate,
    stackParameters,
    setStackParameters,
    stackStatus,
    stackFullDetails,
    refreshStackStatus,
    deployStack,
    stackLatestEvents,
  } = props;

  type StackLatestEvent = {
    resourceType: string;
    status: string;
    logicalId: string;
    physicalId: string;
    timestamp: string;
    resourceStatusReason: string;
  };

  const stackLatestEventList: StackLatestEvent[] = useMemo(() => {
    try {
      const parsed = JSON.parse(stackLatestEvents || "[]");
      // {"StackId":"arn:aws:cloudformation:us-east-1:948939170092:stack/waffle-role/0d2d37c0-73a5-11ef-b7fd-0e2a5d7821b3","EventId":"94e21c40-a450-11ef-9825-0ea540196039","StackName":"waffle-role","LogicalResourceId":"waffle-role","PhysicalResourceId":"arn:aws:cloudformation:us-east-1:948939170092:stack/waffle-role/0d2d37c0-73a5-11ef-b7fd-0e2a5d7821b3","ResourceType":"AWS::CloudFormation::Stack","Timestamp":"2024-11-16T19:25:47.382Z","ResourceStatus":"UPDATE_COMPLETE","ClientRequestToken":"Console-UpdateStack-eef80f19-3929-b7ed-821c-a9245dccaca6"}
      const eventList = parsed.map((event: any) => {
        return {
          resourceType: event.ResourceType,
          status: event.ResourceStatus,
          logicalId: event.LogicalResourceId,
          physicalId: event.PhysicalResourceId,
          timestamp: event.Timestamp,
          resourceStatusReason: event.ResourceStatusReason,
        };
      });
      return eventList;
    } catch (e) {
      return [stackLatestEvents || ""];
    }
  }, [stackLatestEvents]);

  return (
    <PageFrame>
      <PageTitle>My Any Stack</PageTitle>
      <PageLoading loading={loading} />
      <Grid
        container
        spacing={2}
        sx={{ alignItems: "flex-start", flexGrow: 1 }}
      >
        <Grid xs={12}>
          <FormControl>
            <FormLabel>Deployment ID</FormLabel>
            <Select value={selectedDeploymentId || ""}>
              {deploymentIds.map((id) => (
                <Option
                  key={id}
                  value={id}
                  onClick={() => setSelectedDeploymentId(id)}
                >
                  {id}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Stack ID</FormLabel>
            <Select value={selectedStackId || ""}>
              {stackIds.map((id) => (
                <Option
                  key={id}
                  value={id}
                  onClick={() => setSelectedStackId(id)}
                >
                  {id}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <Alert
            color={
              stackStatus === "CREATE_COMPLETE" ||
              stackStatus === "UPDATE_COMPLETE"
                ? "success"
                : (stackStatus || "").indexOf("CREATE") > -1 ||
                  (stackStatus || "").indexOf("UPDATE") > -1
                ? "primary"
                : (stackStatus || "").indexOf("ROLLBACK") > -1
                ? "danger"
                : "neutral"
            }
            variant="soft"
            sx={{ mb: 2 }}
            startDecorator={
              (stackStatus || "").indexOf("IN_PROGRESS") > -1 ? (
                <CircularProgress
                  size="sm"
                  color={
                    stackStatus === "CREATE_COMPLETE" ||
                    stackStatus === "UPDATE_COMPLETE"
                      ? "success"
                      : (stackStatus || "").indexOf("CREATE") > -1 ||
                        (stackStatus || "").indexOf("UPDATE") > -1
                      ? "primary"
                      : (stackStatus || "").indexOf("ROLLBACK") > -1
                      ? "danger"
                      : "neutral"
                  }
                />
              ) : null
            }
            endDecorator={
              (stackStatus || "").indexOf("IN_PROGRESS") === -1 ? (
                <Button
                  size="sm"
                  variant="soft"
                  color={
                    stackStatus === "CREATE_COMPLETE" ||
                    stackStatus === "UPDATE_COMPLETE"
                      ? "success"
                      : (stackStatus || "").indexOf("CREATE") > -1 ||
                        (stackStatus || "").indexOf("UPDATE") > -1
                      ? "primary"
                      : (stackStatus || "").indexOf("ROLLBACK") > -1
                      ? "danger"
                      : "neutral"
                  }
                  onClick={() => {
                    refreshStackStatus();
                  }}
                >
                  Refresh
                </Button>
              ) : null
            }
          >
            Deployment status: {stackStatus}
          </Alert>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Stack Full Details</FormLabel>
            <Textarea value={stackFullDetails || ""} readOnly minRows={3} />
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Stack Latest Events</FormLabel>
            <List>
              {stackLatestEventList.map((event) => (
                <ListItem
                  key={`${event.timestamp}-${event.status}-${event.physicalId}`}
                >
                  <ListItemDecorator>
                    <Chip>{event.timestamp}</Chip>
                  </ListItemDecorator>
                  <ListItemContent>
                    {event.logicalId} {event.resourceType} {event.status}{" "}
                    {event.resourceStatusReason || ""}
                  </ListItemContent>
                </ListItem>
              ))}
            </List>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Stack Template</FormLabel>
            <Textarea
              value={stackTemplate}
              onChange={(e) => setStackTemplate(e.target.value)}
              minRows={3}
              maxRows={20}
            />
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Stack Parameters</FormLabel>
            <Textarea
              value={stackParameters}
              onChange={(e) => setStackParameters(e.target.value)}
              minRows={3}
              maxRows={20}
            />
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <Button
            disabled={
              loading ||
              (stackStatus || "").indexOf("IN_PROGRESS") > -1 ||
              (stackStatus || "").indexOf("DELETE_FAILED") > -1
            }
            onClick={() => deployStack()}
          >
            {(stackStatus || null) === null ? "Deploy" : "Update"} Stack
          </Button>
        </Grid>
      </Grid>
    </PageFrame>
  );
};
