import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import PendingIcon from "@mui/icons-material/Pending";
import Accordion, { accordionClasses } from "@mui/joy/Accordion";
import AccordionDetails, {
  accordionDetailsClasses,
} from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import Box from "@mui/joy/Box";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import { ReactNode } from "react";
import { PageFrame } from "../../components/page_frame";
import { PageLoading } from "../../components/page_loading";
import { PageTitle } from "../../components/page_title";
import { ServiceType, serviceTypeToLabel } from "./service_types";

const CustomAccordionGroup = (props: { children: ReactNode }) => {
  return (
    <AccordionGroup
      variant="plain"
      transition="0.2s"
      sx={{
        // maxWidth: 400,
        [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
          {
            paddingBlock: "1rem",
          },
        [`& .${accordionSummaryClasses.button}`]: {
          paddingBlock: "1rem",
        },
        [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
          // bgcolor: colors.grey2,
          // borderRadius: "md",
          // border: "1px solid",
          // borderColor: "background.level2",
          // mb: 2,
        },
        // '& [aria-expanded="true"]': {
        //   boxShadow: (theme) => `inset 0 -1px 0 ${theme.vars.palette.divider}`,
        // },
      }}
      {...props}
    />
  );
};

export const QuickLaunchComponent = (props: {
  loading: boolean;
  serviceType: ServiceType;
  awsConnectionComponent: ReactNode | null;
  awsConnectionWorks: boolean;
  gitConnectionExists: boolean;
  gitRepositoriesComponent: ReactNode | null;
  deployAllComponent: ReactNode | null;
  resultComponent: ReactNode | null;
  envFeaturesStackStatus: string | null;
}) => {
  const {
    serviceType,
    awsConnectionComponent,
    awsConnectionWorks,
    gitConnectionExists,
    gitRepositoriesComponent,
    deployAllComponent,
    resultComponent,
    loading,
    envFeaturesStackStatus,
  } = props;
  return (
    <PageFrame>
      <Box
        sx={{
          maxWidth: 1024,
          minWidth: 320,
          width: "100%",
          mx: "auto",
        }}
      >
        <PageTitle>{serviceTypeToLabel(serviceType)}</PageTitle>

        <PageLoading loading={loading} />

        <Box sx={{ borderRadius: 6, mt: 6 }}>
          <CustomAccordionGroup>
            <Accordion>
              <AccordionSummary>
                <ListItemDecorator>
                  {awsConnectionWorks ? (
                    <CheckCircleIcon />
                  ) : (
                    <CircleOutlinedIcon />
                  )}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="h3">Connect to AWS</Typography>
                  <Typography level="body-sm">
                    Connect Waffle with your AWS
                  </Typography>
                </ListItemContent>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 2 }}>
                {awsConnectionComponent}
              </AccordionDetails>
            </Accordion>
            <Accordion disabled={!awsConnectionWorks}>
              <AccordionSummary>
                <ListItemDecorator>
                  {gitConnectionExists ? (
                    <CheckCircleIcon />
                  ) : (
                    <CircleOutlinedIcon />
                  )}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="h3">Connect to Git</Typography>
                  <Typography level="body-sm">
                    Connect your AWS account with your Git account
                  </Typography>
                </ListItemContent>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 2 }}>
                {gitRepositoriesComponent}
              </AccordionDetails>
            </Accordion>
            <Accordion disabled={!awsConnectionWorks || !gitConnectionExists}>
              <AccordionSummary>
                <ListItemDecorator>
                  {envFeaturesStackStatus?.includes("COMPLETE") ? (
                    <CheckCircleIcon />
                  ) : envFeaturesStackStatus?.includes("IN_PROGRESS") ? (
                    <PendingIcon />
                  ) : (
                    <CircleOutlinedIcon />
                  )}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="h3">Deploy</Typography>
                  <Typography level="body-sm">
                    Deploy your stack in your AWS
                  </Typography>
                </ListItemContent>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 2 }}>
                {deployAllComponent}
              </AccordionDetails>
            </Accordion>
          </CustomAccordionGroup>
          {resultComponent}
        </Box>
      </Box>
    </PageFrame>
  );
};
