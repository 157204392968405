import { createContext } from "react";
import { EnvironmentsType } from "./type";

const defaultValue: EnvironmentsType = {
  loading: false,
  deployments: [],
  reFetchDeployments: () => {},
  addDeployment: () => {},
  editDeployment: () => {},
  assignDeploymentAccountAndRegion: async () => {},
};

export const EnvironmentsContext =
  createContext<EnvironmentsType>(defaultValue);
