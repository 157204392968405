import { useState } from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import BitBucketIcon from "../../../components/logos/bitbucket-logo.svg";
import GitLabIcon from "../../../components/logos/gitlab-logo-500.svg";
import { PageLoading } from "../../../components/page_loading";
import { GitConnection } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import FormHelperText from "@mui/joy/FormHelperText";
import Link from "@mui/joy/Link";

export const GitConnectionsComponent = (props: {
  loading: boolean;
  createGitConnection: (name: string, providerType: string) => void;
  connections: GitConnection[];
  navigateToHelp: (() => void) | null;
}) => {
  const { loading, createGitConnection, connections, navigateToHelp } = props;
  const [createConnectionName, setCreateConnectionName] = useState<string>("");
  const [createConnectionProvider, setCreateConnectionProvider] =
    useState<string>("GitHub");
  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="body-sm" sx={{ py: 1 }}>
            Connecting your AWS account to your git repositories enables your
            CICD pipelines hosted in your AWS account to deploy your services
            securely.{" "}
            <Link onClick={() => navigateToHelp && navigateToHelp()}>
              Read more
            </Link>
          </Typography>
        </Grid>
        {connections.length > 0 && (
          <Grid xs={12}>
            <Typography level="body-lg" sx={{ py: 2 }}>
              You already have the following connections:
            </Typography>
            <List>
              {connections.map((conn, i) => (
                <ListItem key={i}>
                  <ListItemButton
                    component="a"
                    href={conn.pendingConnectionLink || ""}
                  >
                    <ListItemDecorator>
                      {conn.providerType === "GitHub" ||
                      conn.providerType === "GitHubEnterpriseServer" ? (
                        <GitHubIcon />
                      ) : conn.providerType === "Bitbucket" ? (
                        <Avatar src={BitBucketIcon} />
                      ) : (
                        <Avatar src={GitLabIcon} />
                      )}
                    </ListItemDecorator>
                    <ListItemContent>
                      <Typography level="title-sm">{conn.name}</Typography>
                      <Typography level="body-sm" noWrap>
                        <Typography
                          variant="soft"
                          color={
                            conn.connectionStatus === "AVAILABLE"
                              ? "success"
                              : conn.connectionStatus === "ERROR"
                              ? "danger"
                              : "warning"
                          }
                          fontSize="xs"
                          sx={{ borderRadius: "12px", mx: "4px", px: "8px" }}
                        >
                          {conn.connectionStatus}
                        </Typography>
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            {connections.some(
              (conn) => conn.connectionStatus === "PENDING"
            ) && (
              <Typography level="body-sm" sx={{ py: 1 }}>
                Click on the list items above to approve{" "}
                <Typography
                  variant="soft"
                  color="warning"
                  fontSize="xs"
                  sx={{ borderRadius: "12px", mx: "4px", px: "8px" }}
                >
                  PENDING
                </Typography>{" "}
                connections, and to manage them directly in the AWS console.
              </Typography>
            )}
          </Grid>
        )}
        <Grid xs={12}>
          <Typography level="body-lg" sx={{ pt: 2 }}>
            Create a new connection
          </Typography>
        </Grid>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>Connection name</FormLabel>
            <Input
              placeholder="eg. my repo"
              value={createConnectionName}
              onChange={(event) => setCreateConnectionName(event.target.value)}
              required
            />
            <FormHelperText>
              This is an identifier for your connection.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>Provider</FormLabel>
            <Select
              value={createConnectionProvider}
              onChange={(
                event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                newValue && setCreateConnectionProvider(newValue!);
              }}
            >
              <Option value="Bitbucket">
                <ListItemDecorator>
                  <Avatar size="sm" src={BitBucketIcon} />
                </ListItemDecorator>
                Bitbucket
              </Option>
              <Option value="GitHub">
                <ListItemDecorator>
                  <Avatar size="sm">
                    <GitHubIcon />
                  </Avatar>
                </ListItemDecorator>
                GitHub
              </Option>
              <Option value="GitHubEnterpriseServer">
                <ListItemDecorator>
                  <Avatar size="sm">
                    <GitHubIcon />
                  </Avatar>
                </ListItemDecorator>
                GitHubEnterpriseServer
              </Option>
              <Option value="GitLab">
                <ListItemDecorator>
                  <Avatar size="sm" src={GitLabIcon} />
                </ListItemDecorator>
                GitLab
              </Option>
              <Option value="GitLabSelfManaged">
                <ListItemDecorator>
                  <Avatar size="sm" src={GitLabIcon} />
                </ListItemDecorator>
                GitLabSelfManaged
              </Option>
            </Select>
            <FormHelperText>
              The provider you want to connect to.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>Connect from your AWS account</FormLabel>
            <Button
              disabled={loading || createConnectionName === ""}
              onClick={() =>
                createGitConnection(
                  createConnectionName,
                  createConnectionProvider
                )
              }
            >
              Create Connection
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
