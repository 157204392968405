import { useContext, useEffect, useState } from "react";
import {
  GetMyOrganizationDocument,
  StackStatus,
  useDeployMyApiGatewayStageMutation,
  useGetMyCdnServiceStackPublicUrlLazyQuery,
  useGetMyCdnServiceStackStatusLazyQuery,
  useGetMyDeploymentsQuery,
  useGetMyEcsServiceStackPublicUrlLazyQuery,
  useGetMyEcsServiceStackStatusLazyQuery,
  useGetMyEcsWsServiceStackPublicUrlLazyQuery,
  useGetMyEcsWsServiceStackStatusLazyQuery,
  useSetMyOnboardingCompleteMutation,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { ServiceType, serviceTypeToServiceName } from "../service_types";
import { ResultComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";
import { useNavigate } from "react-router-dom";

export const ResultContainer = (props: {
  deploymentId: string;
  serviceType: ServiceType;
  envFeaturesStackStatus: StackStatus | null;
}) => {
  const { deploymentId, serviceType, envFeaturesStackStatus } = props;
  const pipelineId = serviceTypeToServiceName(serviceType);

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const [stackStatus, setStackStatus] = useState<StackStatus | null>(null);
  const [stackUrl, setStackUrl] = useState<string | null>(null);

  const [
    getMyEcsServiceStackStatus,
    { loading: getMyEcsServiceStackStatusLoading },
  ] = useGetMyEcsServiceStackStatusLazyQuery({
    client: bgwService.getClient(),
    variables: { deploymentId, pipelineId },
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyEcsWsServiceStackStatus,
    { loading: getMyEcsWsServiceStackStatusLoading },
  ] = useGetMyEcsWsServiceStackStatusLazyQuery({
    client: bgwService.getClient(),
    variables: { deploymentId, pipelineId },
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyCdnServiceStackStatus,
    { loading: getMyCdnServiceStackStatusLoading },
  ] = useGetMyCdnServiceStackStatusLazyQuery({
    client: bgwService.getClient(),
    variables: { deploymentId, pipelineId },
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyEcsServiceStackPublicUrl,
    { loading: getMyEcsServiceStackPublicUrlLoading },
  ] = useGetMyEcsServiceStackPublicUrlLazyQuery({
    client: bgwService.getClient(),
    variables: { deploymentId, pipelineId },
    fetchPolicy: "network-only",
  });

  const [
    getMyEcsWsServiceStackPublicUrl,
    { loading: getMyEcsWsServiceStackPublicUrlLoading },
  ] = useGetMyEcsWsServiceStackPublicUrlLazyQuery({
    client: bgwService.getClient(),
    variables: { deploymentId, pipelineId },
    fetchPolicy: "network-only",
  });

  const [
    getMyCdnServiceStackPublicUrl,
    { loading: getMyCdnServiceStackPublicUrlLoading },
  ] = useGetMyCdnServiceStackPublicUrlLazyQuery({
    client: bgwService.getClient(),
    variables: { deploymentId, pipelineId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        [
          ServiceType.http_server,
          ServiceType.http_server_api_key,
          ServiceType.ssr_server,
        ].includes(serviceType)
      ) {
        getMyEcsServiceStackStatus({
          onCompleted: (data) => {
            setStackStatus(data.getMyEcsServiceStackStatus || null);
          },
        });
        getMyEcsServiceStackPublicUrl({
          onCompleted: (data) => {
            setStackUrl(data.getMyEcsServiceStackPublicUrl || null);
          },
        });
      } else if (serviceType === ServiceType.ws_server) {
        getMyEcsWsServiceStackStatus({
          onCompleted: (data) => {
            setStackStatus(data.getMyEcsWsServiceStackStatus || null);
          },
        });
        getMyEcsWsServiceStackPublicUrl({
          onCompleted: (data) => {
            setStackUrl(data.getMyEcsWsServiceStackPublicUrl || null);
          },
        });
      } else if (serviceType === ServiceType.static_web) {
        getMyCdnServiceStackStatus({
          onCompleted: (data) => {
            setStackStatus(data.getMyCdnServiceStackStatus || null);
          },
        });
        getMyCdnServiceStackPublicUrl({
          onCompleted: (data) => {
            setStackUrl(data.getMyCdnServiceStackPublicUrl || null);
          },
        });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [
    getMyEcsServiceStackStatus,
    getMyEcsWsServiceStackStatus,
    getMyEcsServiceStackPublicUrl,
    getMyEcsWsServiceStackPublicUrl,
    getMyCdnServiceStackStatus,
    getMyCdnServiceStackPublicUrl,
    serviceType,
  ]);

  const [
    deployMyApiGatewayStageInProgress,
    setDeployMyApiGatewayStageInProgress,
  ] = useState<boolean>(false);
  const [deployMyApiGatewayStage, { loading: deployMyApiGatewayStageLoading }] =
    useDeployMyApiGatewayStageMutation({
      client: bgwService.getClient(),
      variables: {
        deploymentId,
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
      onCompleted: () => {
        setDeployMyApiGatewayStageInProgress(false);
      },
    });
  const deployApiGateway = () => {
    setDeployMyApiGatewayStageInProgress(true);
    deployMyApiGatewayStage();
  };

  const navigate = useNavigate();
  const navigateToRoot = () => {
    navigate(`/`, {
      replace: true,
    });
  };

  const [setMyOnboardingCompleteMutation] = useSetMyOnboardingCompleteMutation({
    client: bgwService.getClient(),
    refetchQueries: [
      {
        query: GetMyOrganizationDocument,
      },
    ],
    onCompleted: () => {
      navigateToRoot();
    },
  });

  const setMyOnboardingComplete = () => {
    setMyOnboardingCompleteMutation({
      variables: {
        complete: true,
      },
    });
  };

  const { data: getMyDeploymentsData, loading: getMyDeploymentsLoading } =
    useGetMyDeploymentsQuery({
      client: bgwService.getClient(),
      fetchPolicy: "network-only",
    });

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (deployment) => deployment.id === deploymentId
  );

  return (
    <ResultComponent
      serviceType={serviceType}
      envFeaturesStackStatus={envFeaturesStackStatus}
      stackStatus={stackStatus}
      stackUrl={stackUrl}
      loading={
        getMyEcsServiceStackStatusLoading ||
        getMyEcsWsServiceStackStatusLoading ||
        getMyEcsServiceStackPublicUrlLoading ||
        getMyEcsWsServiceStackPublicUrlLoading ||
        getMyCdnServiceStackStatusLoading ||
        getMyCdnServiceStackPublicUrlLoading ||
        deployMyApiGatewayStageLoading ||
        deployMyApiGatewayStageInProgress ||
        getMyDeploymentsLoading
      }
      deployApiGateway={deployApiGateway}
      setMyOnboardingComplete={setMyOnboardingComplete}
      deployment={deployment || null}
    />
  );
};
