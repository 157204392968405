import Markdown, { ExtraProps } from "react-markdown";
import { RightPaneBackButton } from "../../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../../components/right_pane_frame";

const markdown = `
# AWS - Git Connections

Waffle does not coonect to your git repositories, but it does set up a
connection between your AWS account and your selected git repositories.

This is used for CICD pipelines later on, when you are adding services to your environment.

Waffle sets up AWS CodePipeline to deploy your services from your git repositories to your
environment. The connections that you create here enable CodePipeline to access your git repositories directly.

## Setting up your git connections from Waffle

Waffle provides an interface to create a connection from your AWS account, and shows the connection status.

For security purposes Waffle doesn't have access to your git connection details, you need to finish creating
the connection from your AWS account.

Once you create a connection, first it will be in a pending state. If you got to the AWS console, you can
complete the pending connection: it will bring you to your selected git provider, where you can authorize
the connection.

Once you have authorized the connection, you can go back to Waffle and see that the connection is now active.

You can add new connections anytime later on by clicking the "Add connection" button.

If you want to remove a connection, you have to go to the AWS console and remove the connection from there.
`;

const ImageRenderer = (
  props: React.ClassAttributes<HTMLImageElement> &
    React.ImgHTMLAttributes<HTMLImageElement> &
    ExtraProps
) => <img src={props.src} alt={props.alt} style={{ maxWidth: "100%" }} />;

export const GitConnectionsHelpPane = (props: { navigateBack: () => void }) => {
  const { navigateBack } = props;
  return (
    <RightPaneFrame>
      <RightPaneBackButton onClick={navigateBack} labelText="Close" />
      <Markdown components={{ img: ImageRenderer }}>{markdown}</Markdown>
    </RightPaneFrame>
  );
};
