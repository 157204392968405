import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { PageLoading } from "../../../components/page_loading";
import {
  Deployment,
  HostedZone,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import Link from "@mui/joy/Link";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Avatar from "@mui/joy/Avatar";
import Route53Icon from "./route_53.svg";
import ListItemContent from "@mui/joy/ListItemContent";
import { TableFrame } from "../../../components/table_frame";
import Table from "@mui/joy/Table";
import Alert from "@mui/joy/Alert";

export const FullDomainNamesComponent = (props: {
  loading: boolean;
  deployment: Deployment | null;
  customDomainEnabled: boolean;
  existingHostedZones: HostedZone[];
  createHostedZone: (domainName: string) => void;
  hostedZoneStatuses: Record<string, string | null>;
  certDnsStatuses: Record<string, string | null>;
  waffleDomain: string | null;
  createWaffleDomainHostedZone: () => void;
  waffleDomainHostedZoneStatus: string | null;
  createWaffleDomainNsRecord: () => void;
  waffleDomainNsRecordsStatus: string | null;
  navigateToHelp: null | (() => void);
}) => {
  const {
    loading,
    deployment,
    customDomainEnabled,
    existingHostedZones,
    createHostedZone,
    hostedZoneStatuses,
    certDnsStatuses,
    waffleDomain,
    createWaffleDomainHostedZone,
    waffleDomainHostedZoneStatus,
    createWaffleDomainNsRecord,
    waffleDomainNsRecordsStatus,
    navigateToHelp,
  } = props;
  const [newDomainName, setNewDomainName] = useState<string>("");
  const domainValid = /^[a-z,0-9,.,\-,_]+$/.test(newDomainName);
  const domainAlreadyExists =
    deployment?.waffleDomain === newDomainName ||
    hostedZoneStatuses[newDomainName] !== undefined ||
    existingHostedZones.map((z) => z.domainName).includes(newDomainName);

  const [selectedHostedZone, setSelectedHostedZone] =
    useState<HostedZone | null>(null);
  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step is about registering a domain name for this specific
            environment in your AWS account using the AWS Route53 service.{" "}
            <Link onClick={() => navigateToHelp && navigateToHelp()}>
              Read more
            </Link>
          </Typography>
        </Grid>

        {existingHostedZones.length > 0 && (
          <>
            <Grid xs={12}>
              <Typography level="body-lg" sx={{ py: 2 }}>
                You already have the following registered domain names:
              </Typography>
            </Grid>
            <Grid xs={12}>
              <List>
                {existingHostedZones.map((zone, i) => (
                  <ListItem key={i}>
                    <ListItemButton onClick={() => setSelectedHostedZone(zone)}>
                      <ListItemDecorator>
                        <Avatar src={Route53Icon} />
                      </ListItemDecorator>
                      <ListItemContent sx={{ pl: 2 }}>
                        {zone.domainName}
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            {selectedHostedZone &&
              selectedHostedZone.domainName !== waffleDomain && (
                <Grid xs={12}>
                  <Typography level="body-lg" sx={{ py: 2 }}>
                    NS records for {selectedHostedZone.domainName}
                  </Typography>
                  <Typography level="body-xs" sx={{ py: 2 }}>
                    Open in{" "}
                    <Link
                      href={`https://${deployment?.awsRegion}.console.aws.amazon.com/route53/v2/hostedzones?region=${deployment?.awsRegion}#ListRecordSets/${selectedHostedZone.hostedZoneId}`}
                      target="_blank"
                      endDecorator={<OpenInNewIcon />}
                    >
                      AWS Route53
                    </Link>
                  </Typography>
                  <TableFrame cellHeight={40} tableHeight={200}>
                    <Table hoverRow stickyHeader>
                      <thead>
                        <tr>
                          <th style={{ width: "100" }}>Host</th>
                          <th style={{ width: "100" }}>Type</th>
                          <th style={{ width: "100" }}>TTL</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedHostedZone.nameServers?.map((ns) => (
                          <tr key={ns}>
                            <td>
                              {selectedHostedZone.domainName
                                ?.split(".")
                                .splice(0)
                                .join(".")}
                            </td>
                            <td>NS</td>
                            <td>300</td>
                            <td>{ns}.</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TableFrame>
                  <Alert color="neutral" variant="soft" sx={{ my: 2 }}>
                    Heads-up: Waffle doesn't check if the NS records above are
                    in place. But it's required for the services to work.
                  </Alert>
                </Grid>
              )}
            {selectedHostedZone &&
              selectedHostedZone.domainName === waffleDomain && (
                <Grid xs={12} sm={8}>
                  <Typography level="body-lg" sx={{ py: 2 }}>
                    {selectedHostedZone.domainName} is handled by Waffle.
                  </Typography>
                </Grid>
              )}
          </>
        )}

        {hostedZoneStatuses && Object.keys(hostedZoneStatuses).length > 0 && (
          <Grid xs={12}>
            <Typography level="body-lg" sx={{ py: 2 }}>
              The following domain names with SSL certificates are being
              created:
            </Typography>
            <List>
              {Object.entries(hostedZoneStatuses).map(
                ([domainName, status]) => (
                  <ListItem key={domainName}>
                    <Typography level="body-md">{domainName}</Typography>{" "}
                    <Typography
                      variant="soft"
                      color="warning"
                      fontSize="xs"
                      sx={{ borderRadius: "12px", mx: "4px", px: "8px" }}
                    >
                      {status}
                    </Typography>{" "}
                    {certDnsStatuses[domainName] && (
                      <>
                        SSL status:{" "}
                        <Typography
                          variant="soft"
                          color="warning"
                          fontSize="xs"
                          sx={{ borderRadius: "12px", mx: "4px", px: "8px" }}
                        >
                          {certDnsStatuses[domainName]}
                        </Typography>
                      </>
                    )}
                  </ListItem>
                )
              )}
            </List>
          </Grid>
        )}

        {existingHostedZones.length === 0 && waffleDomain && (
          <>
            <Grid xs={12}>
              <Typography level="body-lg" sx={{ py: 2 }}>
                Waffle-generated domain name
              </Typography>
              <Typography level="body-sm">
                This is a free option that doesn't require any domain name
                registration on your end.
              </Typography>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Waffle Domain name</FormLabel>
                <Input value={waffleDomain || ""} readOnly />
              </FormControl>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Create in your AWS account</FormLabel>
                {waffleDomainHostedZoneStatus === null && (
                  <Button onClick={createWaffleDomainHostedZone}>
                    Register Domain Name
                  </Button>
                )}
                {waffleDomainHostedZoneStatus === "IN_PROGRESS" && (
                  <Typography level="body-md" sx={{ py: 1 }}>
                    The domain name is being created.
                  </Typography>
                )}
                {waffleDomainNsRecordsStatus === "IN_PROGRESS" && (
                  <Typography level="body-md" sx={{ py: 1 }}>
                    The domain name is being registered with Waffle.
                  </Typography>
                )}
                {waffleDomainHostedZoneStatus === "COMPLETED" &&
                  waffleDomainNsRecordsStatus === null && (
                    <Button onClick={createWaffleDomainNsRecord}>
                      Register with Waffle
                    </Button>
                  )}
              </FormControl>
            </Grid>
          </>
        )}

        {customDomainEnabled && (
          <>
            <Grid xs={12}>
              <Typography level="body-lg" sx={{ py: 2 }}>
                Register a new domain name
              </Typography>
              <Typography level="body-sm">
                If you let's say own the domain name example.com, you can
                register a subdomain name like myapp.example.com in your AWS
                account.
              </Typography>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Domain name</FormLabel>
                <Stack spacing={0.5}>
                  <Input
                    value={newDomainName}
                    onChange={(event) => {
                      setNewDomainName(event.target.value);
                    }}
                  />
                  <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                    {newDomainName !== "" && !domainValid
                      ? "Invalid value"
                      : ""}
                    {newDomainName !== "" && domainAlreadyExists
                      ? "Domain already exists"
                      : ""}
                  </Typography>
                </Stack>
                <FormHelperText>For example myapp.example.com .</FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Create in your AWS account</FormLabel>
                <Button
                  disabled={
                    loading ||
                    newDomainName === "" ||
                    !domainValid ||
                    domainAlreadyExists
                  }
                  onClick={() => {
                    createHostedZone(newDomainName);
                    setNewDomainName("");
                  }}
                >
                  Register Domain Name
                </Button>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
