import Box from "@mui/joy/Box";
import { ReactNode } from "react";
import { HEADER_HEIGHT } from "../constants";

export const FixedLeftDrawer = (props: { children?: ReactNode; sx?: any }) => {
  return (
    <Box
      {...props}
      sx={[
        {
          p: 0,
          m: 0,
          bgcolor: "neutral.900",
          borderRight: `1px solid`,
          borderRightColor: "neutral.800",
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "initial",
          },
          gridRow: 1,
          gridColumn: 1,
          height: {
            xs: "100vh",
            sm: "100vh",
            md: "100vh",
            lg: `calc(100vh - ${HEADER_HEIGHT})`,
          },
          overflow: "auto",
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      aria-label="fixed-left-drawer"
    />
  );
};
