import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/joy/Button";
import {
  GitConnection,
  StackStatus,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import Grid from "@mui/joy/Grid";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import GitHubIcon from "@mui/icons-material/GitHub";
import BitBucketIcon from "../../../components/logos/bitbucket-logo.svg";
import GitLabIcon from "../../../components/logos/gitlab-logo-500.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import Input from "@mui/joy/Input/Input";
// import { PageLoading } from "../../../components/page_loading";
import FormHelperText from "@mui/joy/FormHelperText";
import Avatar from "@mui/joy/Avatar";
import { ServiceType, serviceTypeToLabel } from "../service_types";
import Alert from "@mui/joy/Alert";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";

export const DeployAllComponent = (props: {
  loading: boolean;
  deployAllInProgress: boolean;
  gitConnections: GitConnection[];
  startDeploy: (
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    dockerfilePath: string | null,
    servicePort: number | null,
    healthCheckPath: string | null,
    buildScriptPath: string | null,
    buildFolderPath: string | null
  ) => void;
  envFeaturesStackStatus: StackStatus | null;
  ecsServiceStackStatus: StackStatus | null;
  ecsWsServiceStackStatus: StackStatus | null;
  cdnServiceStackStatus: StackStatus | null;
  serviceType: ServiceType;
}) => {
  const {
    loading,
    deployAllInProgress,
    gitConnections,
    startDeploy,
    envFeaturesStackStatus,
    ecsServiceStackStatus,
    ecsWsServiceStackStatus,
    cdnServiceStackStatus,
    serviceType,
  } = props;

  let serviceStackStatus = ecsServiceStackStatus;
  if (serviceType === ServiceType.ws_server) {
    serviceStackStatus = ecsWsServiceStackStatus;
  } else if (serviceType === ServiceType.static_web) {
    serviceStackStatus = cdnServiceStackStatus;
  }

  const sampleRepo: string | undefined = // This requires a github connection
    (
      {
        // [ServiceType.http_server]: "backend-ecs-fastapi-py/Dockerfile",
        // [ServiceType.http_server_api_key]: "backend-ecs-fastapi-py/Dockerfile",
        // [ServiceType.ssr_server]: "some-user/my-repo",
        // [ServiceType.ws_server]: "some-user/my-repo",
      } as Record<ServiceType, string | undefined>
    )[serviceType];

  const [gitConnectionArn, setGitConnectionArn] = useState<string>("");
  const [gitRepositoryId, setGitRepositoryId] = useState<string>(
    sampleRepo ? "WaffleCodeApp/waffle-examples" : ""
  );
  const [selectedBranch, setSelectedBranch] = useState<string>(
    sampleRepo ? "main" : ""
  );
  const [dockerfilePath, setDockerfilePath] = useState<string>(
    sampleRepo || ""
  );
  const [servicePort, setServicePort] = useState<number>(80);
  const [healthCheckPath, setHealthCheckPath] = useState<string>(
    sampleRepo ? "/health_check" : ""
  );
  const [buildScriptPath, setBuildScriptPath] = useState<string>(
    sampleRepo ? "" : ""
  );
  const [buildFolderPath, setBuildFolderPath] = useState<string>(
    sampleRepo ? "" : ""
  );

  const serviceStackParams = useMemo(() => {
    if ([ServiceType.http_server].includes(serviceType)) {
      return JSON.parse(ecsServiceStackStatus?.parametersJSON || "{}");
    } else if ([ServiceType.static_web].includes(serviceType)) {
      return JSON.parse(cdnServiceStackStatus?.parametersJSON || "{}");
    } else if ([ServiceType.ws_server].includes(serviceType)) {
      return JSON.parse(ecsWsServiceStackStatus?.parametersJSON || "{}");
    }
    return {};
  }, [
    ecsServiceStackStatus,
    cdnServiceStackStatus,
    ecsWsServiceStackStatus,
    serviceType,
  ]);

  useEffect(() => {
    if (serviceStackParams.gitConnectionArn) {
      setGitConnectionArn(
        serviceStackParams.gitConnectionArn || gitConnectionArn
      );
    }
    if (serviceStackParams.gitRepositoryId) {
      setGitRepositoryId(serviceStackParams.gitRepositoryId || gitRepositoryId);
    }
    if (serviceStackParams.selectedBranch) {
      setSelectedBranch(serviceStackParams.selectedBranch || selectedBranch);
    }
    if (serviceStackParams.dockerfilePath) {
      setDockerfilePath(serviceStackParams.dockerfilePath || dockerfilePath);
    }
    if (serviceStackParams.servicePort) {
      setServicePort(serviceStackParams.servicePort || servicePort);
    }
    if (serviceStackParams.healthCheckPath) {
      setHealthCheckPath(serviceStackParams.healthCheckPath || healthCheckPath);
    }
    if (serviceStackParams.buildScriptPath) {
      setBuildScriptPath(serviceStackParams.buildScriptPath || buildScriptPath);
    }
    if (serviceStackParams.buildFolderPath) {
      setBuildFolderPath(serviceStackParams.buildFolderPath || buildFolderPath);
    }
  }, [
    serviceStackParams,
    gitConnectionArn,
    gitRepositoryId,
    selectedBranch,
    dockerfilePath,
    servicePort,
    healthCheckPath,
    buildScriptPath,
    buildFolderPath,
  ]);

  useEffect(() => {
    if (gitConnections.length > 0 && gitConnectionArn === "") {
      setGitConnectionArn(gitConnections[0].id || "");
    }
  }, [gitConnections, gitConnectionArn]);

  const servicePortInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {/* <PageLoading loading={loading} /> */}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="body-lg" sx={{ py: 2 }}>
            Select your git repository and branch
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Git connection</FormLabel>
            <Select
              placeholder="Select Git Connection"
              value={gitConnectionArn}
              slotProps={{
                listbox: {
                  sx: {
                    maxHeight: 300,
                    overflow: "auto", // required for scrolling
                    zIndex: 1201, // Because of the floating drawer on mobile
                  },
                },
              }}
            >
              {gitConnections?.map((conn, i) => (
                <Option
                  value={conn.id}
                  key={conn.id || i}
                  onClick={() => {
                    setGitConnectionArn(conn.id || "");
                  }}
                >
                  <ListItemDecorator>
                    {conn.providerType === "GitHub" ||
                    conn.providerType === "GitHubEnterpriseServer" ? (
                      <GitHubIcon />
                    ) : conn.providerType === "Bitbucket" ? (
                      <Avatar src={BitBucketIcon} />
                    ) : (
                      <Avatar src={GitLabIcon} />
                    )}
                  </ListItemDecorator>
                  {conn.name}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              Select the connection to your git provider that has the repository
              that you want to deploy
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Git Repository</FormLabel>
            <Input
              value={gitRepositoryId}
              onChange={(event) => {
                setGitRepositoryId(event.target.value);
              }}
            />
            <FormHelperText>
              The owner and name of the repo expected in the following format:
              some-user/my-repo
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Branch</FormLabel>
            <Input
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
            />
            <FormHelperText>
              Set the name of the branch that you want to automatically deploy
              from on every commit.
            </FormHelperText>
          </FormControl>
        </Grid>

        {[
          ServiceType.http_server,
          ServiceType.ssr_server,
          ServiceType.ws_server,
          ServiceType.http_server_api_key,
        ].includes(serviceType) && (
          <>
            <Grid xs={12}>
              <Typography level="body-lg" sx={{ py: 2 }}>
                Set your Dockerfile details
              </Typography>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Dockerfile path</FormLabel>
                <Input
                  value={dockerfilePath}
                  onChange={(e) => setDockerfilePath(e.target.value)}
                />
                <FormHelperText>
                  Set path to the Dockerfile you want to deploy from. The root
                  in the Dockerfile is the repository root.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Service port</FormLabel>
                <Input
                  value={servicePort}
                  type="number"
                  slotProps={{
                    input: {
                      ref: servicePortInputRef,
                      min: 0,
                      max: 65535,
                      step: 1,
                    },
                  }}
                  onChange={(e) => setServicePort(parseInt(e.target.value))}
                />
                <FormHelperText>
                  Set the port your service will be running on inside the
                  container.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Health-check path</FormLabel>
                <Input
                  value={healthCheckPath}
                  onChange={(e) => setHealthCheckPath(e.target.value)}
                />
                <FormHelperText>
                  Set the path to the health-check endpoint. The health-check
                  endpoint has to respond with 2xx to HTTP GET requests. If it
                  fails to respond with 2xx, service will be considered
                  unhealthy and a new container will be started.
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}

        {[ServiceType.static_web].includes(serviceType) && (
          <>
            <Grid xs={12}>
              <Typography level="body-lg" sx={{ py: 2 }}>
                Your build configuration
              </Typography>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Build command</FormLabel>
                <Input
                  placeholder="npm i && npm run build"
                  value={buildScriptPath}
                  onChange={(e) => setBuildScriptPath(e.target.value)}
                />
                <FormHelperText>
                  Set the the build command that will be used to build the
                  static frontend files from the source code. Has to include the
                  path in the repo.
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Build folder path</FormLabel>
                <Input
                  placeholder="dist"
                  value={buildFolderPath}
                  onChange={(e) => setBuildFolderPath(e.target.value)}
                />
                <FormHelperText>
                  Set the path to the folder where the build script produces the
                  static frontend files.
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}

        <Grid xs={12}>
          {envFeaturesStackStatus?.status?.includes("CREATE_FAILED") ? (
            <Alert color="danger" variant="soft">
              Deployment failed.
            </Alert>
          ) : !serviceStackStatus?.status?.includes("COMPLETE") ? (
            <Button
              size="lg"
              sx={{ my: 2 }}
              // loading={
              //   envFeaturesStackStatus?.status?.includes("IN_PROGRESS") ||
              //   serviceStackStatus?.status?.includes("IN_PROGRESS")
              // }
              startDecorator={
                (envFeaturesStackStatus?.status?.includes("IN_PROGRESS") ||
                  serviceStackStatus?.status?.includes("IN_PROGRESS")) && (
                  <CircularProgress size="sm" />
                )
              }
              disabled={
                loading ||
                deployAllInProgress ||
                !gitConnectionArn ||
                !gitRepositoryId ||
                !selectedBranch ||
                envFeaturesStackStatus?.status?.includes("IN_PROGRESS") ||
                serviceStackStatus?.status?.includes("IN_PROGRESS")
              }
              onClick={() => {
                if (gitConnectionArn && gitRepositoryId && selectedBranch) {
                  startDeploy(
                    gitConnectionArn,
                    gitRepositoryId,
                    selectedBranch,
                    dockerfilePath,
                    servicePort,
                    healthCheckPath,
                    buildScriptPath,
                    buildFolderPath
                  );
                }
              }}
            >
              {envFeaturesStackStatus?.status?.includes("IN_PROGRESS")
                ? "Deploying secure environment..."
                : serviceStackStatus?.status?.includes("IN_PROGRESS")
                ? `Deploying ${serviceTypeToLabel(serviceType)}...`
                : "Deploy"}
            </Button>
          ) : (
            <Chip
              variant="solid"
              color="success"
              size="md"
              sx={{ mt: 1 }}
              startDecorator={<CheckCircleIcon />}
            >
              Deployed
            </Chip>
          )}
        </Grid>
      </Grid>
    </>
  );
};
