export const ROUTES = {
  root: { path: "/" },
  dashboard_with_deployment: { path: "/dashboard/:deploymentId" },
  deployment_steps: { path: "/deployment_steps" },
  deployment_steps_with_step: {
    path: "/deployment_steps/:deploymentId/:stepId",
  },
  my_environment: {
    path: "/my_environment/:deploymentId",
  },
  my_environment_with_help: {
    path: "/my_environment/:deploymentId/help/:stepId",
  },
  quick_launch: { path: "/quick_launch" },
  quick_launch_with_deployment: {
    path: "/quick_launch/:serviceType/:deploymentId",
  },
  quick_launch_with_deployment_and_help: {
    path: "/quick_launch/:serviceType/:deploymentId/help/:helpId",
  },
  service_statuses: { path: "/service_statuses" },
  service_statuses_with_stack: { path: "/service_statuses/:stackId" },
  my_team: { path: "/my_team" },
  my_team_with_user: { path: "/my_team/:userId" },
  organizations: { path: "/organizations" },
  organizations_with_organization: { path: "/organizations/:organizationId" },
  profile: { path: "/profile" },
  my_any_stack: { path: "/my_any_stack" },
};
