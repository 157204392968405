import { useContext } from "react";
import { SetOrganizationComponent } from "./component";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { AlertsContext } from "../../contexts/alerts/context";
import { AlertType } from "../../contexts/alerts/type";
import {
  GetAllOrganizationsDocument,
  GetMyOrganizationDocument,
  useSetMyOrganizationMutation,
  WhoAmIDocument,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { gql } from "@apollo/client";

export const SetOrganizationContainer = (props: {
  navigateWhenFinished: () => void;
}) => {
  const { navigateWhenFinished } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const [setMyOrganization, { loading: setMyOrganizationLoading }] =
    useSetMyOrganizationMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const setOrganizationName = async (organizationName: string) => {
    const organizationIdArray = organizationName
      .toLocaleLowerCase()
      .match(/[a-z0-9]/g);
    const organizationId = (organizationIdArray || []).join("");
    if (organizationId === "") {
      addAlert({
        text: "Please use letters in the organization name",
        type: AlertType.DANGER,
      });
      return;
    }

    await setMyOrganization({
      variables: {
        organizationName,
      },
      optimisticResponse: () => ({
        setMyOrganization: {
          id: organizationId,
          name: organizationName,
          onboardingComplete: false,
          features: {
            singleDeployment: true,
          },
        },
      }),
      update(cache, { data }) {
        cache.modify({
          fields: {
            getMyOrganization() {
              if (data?.setMyOrganization) {
                const newOrganizationRef = cache.writeFragment({
                  id: `Organization:${data.setMyOrganization.id}`,
                  data: data.setMyOrganization,
                  fragment: gql`
                    fragment OrganizationDetail on Organization {
                      id
                      name
                      onboardingComplete
                      features {
                        singleDeployment
                      }
                    }
                  `,
                });
                return newOrganizationRef!;
              }
              return null;
            },
            getAllOrganizations(existingOrganizations = []) {
              const newOrganizationRef = cache.writeFragment({
                data: data?.setMyOrganization,
                fragment: gql`
                  fragment NewOrganization on Organization {
                    id
                    name
                  }
                `,
              });
              return [...existingOrganizations, newOrganizationRef];
            },
            whoAmI(existingWhoAmI) {
              return {
                ...existingWhoAmI,
                organizationId: data?.setMyOrganization?.id,
                role: "ADMIN",
              };
            },
          },
        });
      },
      refetchQueries: [
        {
          query: GetMyOrganizationDocument,
        },
        {
          query: GetAllOrganizationsDocument,
        },
        {
          query: WhoAmIDocument,
        },
      ],
      onCompleted: () => {
        if (navigateWhenFinished) {
          navigateWhenFinished();
        }
      },
    });
  };

  return (
    <SetOrganizationComponent
      loading={setMyOrganizationLoading}
      setOrganizationName={setOrganizationName}
    />
  );
};
