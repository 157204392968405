export enum ServiceType {
  http_server = "http_server",
  http_server_api_key = "http_server_api_key",
  ssr_server = "ssr_server",
  ws_server = "ws_server",
  static_web = "static_web",
  // custom_resources = "custom_resources",
}

export const serviceTypeToLabel = (serviceType: ServiceType) => {
  switch (serviceType) {
    case ServiceType.http_server:
      return "HTTP Backend Server";
    case ServiceType.http_server_api_key:
      return "HTTP Server with API Key";
    case ServiceType.ssr_server:
      return "Server-side rendered Web App";
    case ServiceType.ws_server:
      return "WebSocket Server";
    case ServiceType.static_web:
      return "Static Web App";
    // case ServiceType.custom_resources:
    //   return "Custom Resources";
    default:
      return `${serviceType}`;
  }
};

export const serviceTypeToType = (serviceType: ServiceType) => {
  switch (serviceType) {
    case ServiceType.http_server:
      return "ECS";
    case ServiceType.http_server_api_key:
      return "ECS";
    case ServiceType.ssr_server:
      return "ECS";
    case ServiceType.ws_server:
      return "ECS_WS";
    case ServiceType.static_web:
      return "CDN";
    // case ServiceType.custom_resources:
    //   return "CFN";
    default:
      return `${serviceType}`;
  }
};

export const serviceTypeToServiceName = (serviceType: ServiceType) => {
  switch (serviceType) {
    case ServiceType.http_server:
      return "httpserver";
    case ServiceType.http_server_api_key:
      return "httpserverapikey";
    case ServiceType.ssr_server:
      return "ssrserver";
    case ServiceType.ws_server:
      return "wsserver";
    case ServiceType.static_web:
      return "staticweb";
    // case ServiceType.custom_resources:
    //   return "customresources";
    default:
      return `${serviceType}`;
  }
};
