import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import { ReactNode } from "react";

export const FloatingLeftDrawer = (props: {
  children?: ReactNode;
  drawerOpen: boolean;
  drawerWidth: number;
  handleDrawerToggle: any;
  sx?: any;
}) => {
  const { drawerOpen, drawerWidth, handleDrawerToggle, children } = props;
  if (!drawerOpen) return null;
  return (
    <Box
      sx={[
        {
          position: "fixed",
          zIndex: 1200,
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        },
      ]}
      aria-label="floating-left-drawer"
    >
      <Box
        role="button"
        onClick={handleDrawerToggle}
        sx={{
          position: "absolute",
          inset: 0,
          bgcolor: "background.backdrop",
          backdropFilter: "blur(3px)",
        }}
      />
      <Sheet
        sx={{
          width: drawerWidth,
          height: "100%",
          boxShadow: "lg",
          bgcolor: "neutral.900",
        }}
      >
        {children}
      </Sheet>
    </Box>
  );
};
