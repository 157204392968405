import { useNavigate } from "react-router-dom";
import { QuickLaunchComponent } from "./component";
import { useContext, useEffect, useState } from "react";
import { AwsConnectionContainer } from "./aws_connection/container";
import { GitRepositoriesContainer } from "./git_repo/container";
import { EnvironmentsContext } from "../../contexts/environments/context";
import { ServiceType } from "./service_types";
import { DeployAllContainer } from "./deploy_all/container";
import {
  StackStatus,
  useDeployMyEnvFeaturesStackMutation,
  useGetMyEnvFeaturesStackStatusLazyQuery,
  useGetMyGitConnectionsLazyQuery,
  useTestMyWaffleRoleQuery,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { AlertsContext } from "../../contexts/alerts/context";
import { AlertType } from "../../contexts/alerts/type";
import { ResultContainer } from "./result/container";

export const QuickLaunchContainer = (props: {
  loading: boolean;
  serviceType: ServiceType;
  deploymentId: string;
}) => {
  const { serviceType, deploymentId, loading } = props;

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const { loading: envLoading } = useContext(EnvironmentsContext);

  const navigate = useNavigate();

  const navigateToHelp = (stepId: string) => {
    navigate(`/quick_launch/${serviceType}/${deploymentId}/help/${stepId}`, {
      replace: true,
    });
  };

  const [awsConnectionWorks, setAwsConnectionWorks] = useState<boolean>(false);
  const [gitConnectionExists, setGitConnectionExists] =
    useState<boolean>(false);
  const [envFeaturesStackStatus, setEnvFeaturesStackStatus] =
    useState<StackStatus | null>(null);

  const {
    data: testMyWaffleRoleData,
    loading: testMyWaffleRoleLoading,
    refetch: refetchTestMyWaffleRole,
  } = useTestMyWaffleRoleQuery({
    client: bgwService.getClient(),
    variables: { deploymentId },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [getMyGitConnections, { loading: getMyGitConnectionsLoading }] =
    useGetMyGitConnectionsLazyQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        // if (error?.message !== undefined) {
        //   addAlert({
        //     text: error.message!,
        //     type: AlertType.WARNING,
        //   });
        // }
      },
      fetchPolicy: "network-only",
    });

  const [
    getMyEnvFeaturesStackStatus,
    {
      loading: getMyEnvFeaturesStackStatusLoading,
      refetch: refetchGetMyEnvFeaturesStackStatus,
    },
  ] = useGetMyEnvFeaturesStackStatusLazyQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [envDeploymentLoading, setEnvDeploymentLoading] =
    useState<boolean>(false);

  const [
    deployMyEnvFeaturesStack,
    { loading: deployMyEnvFeaturesStackLoading },
  ] = useDeployMyEnvFeaturesStackMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      setEnvDeploymentLoading(false);
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const pollEnvFeaturesStackStatus = (
    deploymentId: string,
    stackId: string | null,
    callback: null | (() => void) = null
  ) => {
    const pollInterval = 5000; // poll every 5 seconds
    const poll = async () => {
      try {
        const { data } = await refetchGetMyEnvFeaturesStackStatus({
          deploymentId,
          stackId,
        });
        const status = data?.getMyEnvFeaturesStackStatus;
        // Update your state with the latest status
        setEnvFeaturesStackStatus(() => status || null);
        if (
          !status ||
          (status && (!status.status || status.status.includes("IN_PROGRESS")))
        ) {
          setTimeout(poll, pollInterval);
        } else if (
          status &&
          (status.status === "CREATE_COMPLETE" ||
            status.status === "UPDATE_COMPLETE")
        ) {
          if (callback) {
            callback();
          }
        }
      } catch (error) {
        // If there's an error, you might want to try again
        setTimeout(poll, pollInterval);
      }
    };
    poll();
  };

  const updateEnvFeaturesStack = (
    deploymentId: string,
    fullDomainName: string,
    emailNotificationList: string,
    logRetentionDays: number,
    restApiV1Enabled: boolean,
    restApiV1Subdomain: string,
    httpApiV2Enabled: boolean,
    httpApiV2Subdomain: string,
    vpcEnabled: boolean,
    vpcCidr: string,
    primaryPrivateCidr: string,
    secondaryPrivateCidr: string,
    primaryPublicCidr: string,
    secondaryPublicCidr: string,
    cognitoEnabled: boolean,
    cognitoUserPoolEnabled: boolean,
    cognitoAllowAdminCreateUserOnly: boolean,
    cognitoAlertsEnabled: boolean,
    cognitoOidcEnabled: boolean,
    cognitoOidcUrl: string,
    cognitoOidcClientId: string,
    cognitoOidcThumbprint: string,
    callback: null | (() => void) = null
  ) => {
    setEnvDeploymentLoading(true);
    deployMyEnvFeaturesStack({
      variables: {
        envFeaturesStackInput: {
          deploymentId,
          fullDomainName,
          emailNotificationList,
          logRetentionDays,
          restApiV1Enabled,
          restApiV1Subdomain,
          httpApiV2Enabled,
          httpApiV2Subdomain,
          vpcEnabled,
          vpcCidr,
          primaryPrivateCidr,
          secondaryPrivateCidr,
          primaryPublicCidr,
          secondaryPublicCidr,
          cognitoEnabled,
          cognitoUserPoolEnabled,
          cognitoAllowAdminCreateUserOnly,
          cognitoAlertsEnabled,
          cognitoOidcEnabled,
          cognitoOidcUrl,
          cognitoOidcClientId,
          cognitoOidcThumbprint,
        },
      },
      onCompleted: (data) => {
        setEnvDeploymentLoading(false);
        pollEnvFeaturesStackStatus(
          deploymentId,
          data?.deployMyEnvFeaturesStack?.stackId || null,
          callback
        );
      },
    });
  };

  const retestMyWaffleRole = async () => {
    await refetchTestMyWaffleRole();
  };

  useEffect(() => {
    if (testMyWaffleRoleData) {
      const passed = testMyWaffleRoleData.testMyWaffleRole?.passed || false;
      setAwsConnectionWorks(() => passed);
      if (passed) {
        console.log("testMyWaffleRole: passed");
        getMyGitConnections({
          variables: {
            deploymentId,
          },
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            setGitConnectionExists(
              () => (data.getMyGitConnections?.length || 0) > 0
            );
          },
          onError: (error) => {
            console.log("testMyWaffleRole: getMyGitConnections error", error);
          },
        });
        console.log("testMyWaffleRole: getMyGitConnections fired");
        getMyEnvFeaturesStackStatus({
          variables: {
            deploymentId,
          },
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            setEnvFeaturesStackStatus(
              (prev) => data.getMyEnvFeaturesStackStatus || null
            );
          },
        });
      } else {
        console.log("testMyWaffleRole: failed");
        setGitConnectionExists(false);
        setEnvFeaturesStackStatus(null);
      }
    }
  }, [
    testMyWaffleRoleData,
    deploymentId,
    getMyGitConnections,
    getMyEnvFeaturesStackStatus,
  ]);

  const awsConnectionComponent = (
    <AwsConnectionContainer
      deploymentId={deploymentId}
      navigateToHelp={navigateToHelp}
      awsConnectionWorks={awsConnectionWorks}
      refetchTestMyWaffleRole={retestMyWaffleRole}
    />
  );
  const gitRepositoriesComponent = (
    <GitRepositoriesContainer
      deploymentId={deploymentId}
      navigateToHelp={navigateToHelp}
      setGitConnectionExists={() => setGitConnectionExists(true)}
    />
  );
  const deployAllComponent = (
    <DeployAllContainer
      deploymentId={deploymentId}
      serviceType={serviceType}
      navigateToHelp={navigateToHelp}
      envFeaturesStackStatus={envFeaturesStackStatus}
      updateEnvFeaturesStack={updateEnvFeaturesStack}
    />
  );
  const resultComponent = (
    <ResultContainer
      deploymentId={deploymentId}
      serviceType={serviceType}
      envFeaturesStackStatus={envFeaturesStackStatus}
    />
  );

  return (
    <QuickLaunchComponent
      loading={
        loading ||
        envLoading ||
        testMyWaffleRoleLoading ||
        getMyGitConnectionsLoading ||
        getMyEnvFeaturesStackStatusLoading ||
        deployMyEnvFeaturesStackLoading ||
        envDeploymentLoading
      }
      serviceType={serviceType}
      awsConnectionWorks={awsConnectionWorks}
      gitConnectionExists={gitConnectionExists}
      awsConnectionComponent={awsConnectionComponent}
      gitRepositoriesComponent={gitRepositoriesComponent}
      deployAllComponent={deployAllComponent}
      resultComponent={resultComponent}
      envFeaturesStackStatus={envFeaturesStackStatus?.status || null}
    />
  );
};
