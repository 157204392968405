import { useContext, useEffect, useState } from "react";
import {
  useGetMyWaffleCfnTemplateQuery,
  useTempTestMyWaffleRoleMutation,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AwsConnectionComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { EnvironmentsContext } from "../../../contexts/environments/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";

export const AwsConnectionContainer = (props: {
  deploymentId: string;
  navigateToHelp: (stepId: string) => void;
  awsConnectionWorks: boolean;
  refetchTestMyWaffleRole: () => Promise<void>;
}) => {
  const {
    deploymentId,
    navigateToHelp,
    awsConnectionWorks,
    refetchTestMyWaffleRole,
  } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const {
    loading: envLoading,
    deployments,
    assignDeploymentAccountAndRegion,
  } = useContext(EnvironmentsContext);

  const deployment = deployments.find((d) => d.id === deploymentId);
  const [accountId, setAccountId] = useState<string | null>(null);
  const [awsRegion, setAwsRegion] = useState<string | null>(null);

  useEffect(() => {
    if (deployment) {
      setAccountId(deployment.accountId || null);
      setAwsRegion(deployment.awsRegion || null);
    }
  }, [deployment]);

  const updateAccountId = (accountId: string) => {
    if (deployment?.accountId === "") setAccountId(accountId);
  };

  const updateAwsRegion = (awsRegion: string) => {
    if (deployment?.awsRegion === "") setAwsRegion(awsRegion);
  };

  const { data: getCfnData, loading: getCfnLoading } =
    useGetMyWaffleCfnTemplateQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const downloadCfnJsonBlob = (): void => {
    const jsonContent = getCfnData?.getMyWaffleCfnTemplate || null;
    if (jsonContent === null) {
      addAlert({
        text: "Failed to download the Waffle CloudFormation template",
        type: AlertType.WARNING,
      });
      return;
    }
    const blob = new Blob([jsonContent], {
      type: "application/json;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "waffle-role-cloudformation.json");
    pom.click();
    pom.remove();
  };

  const [tempTestMyWaffleRole, { loading: tempTestMyWaffleRoleLoading }] =
    useTempTestMyWaffleRoleMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const checkAndConfirmAwsConnection = () => {
    if (deployment && accountId && awsRegion) {
      tempTestMyWaffleRole({
        variables: {
          accountId: accountId,
        },
        onCompleted: async (data) => {
          if (data?.tempTestMyWaffleRole?.passed === true) {
            await assignDeploymentAccountAndRegion(
              deploymentId,
              accountId,
              awsRegion
            );
            await refetchTestMyWaffleRole();
          }
        },
      });
    }
  };

  return (
    <AwsConnectionComponent
      loading={getCfnLoading || envLoading || tempTestMyWaffleRoleLoading}
      accountId={accountId}
      awsRegion={awsRegion}
      deployment={deployment || null}
      updateAccountId={updateAccountId}
      updateAwsRegion={updateAwsRegion}
      downloadCfnJsonBlob={downloadCfnJsonBlob}
      connectionWorks={awsConnectionWorks}
      checkConnection={() => checkAndConfirmAwsConnection()}
      navigateToHelp={() => navigateToHelp("aws_connection")}
    />
  );
};
