import { FC, ReactNode, useContext } from "react";
import { DrawerContext } from "../../../contexts/drawer/context";
import { LayoutComponent } from "./components";

export const LayoutContainer = (props: {
  children: ReactNode;
  drawerContent: (props: { drawerWidth: number }) => JSX.Element;
  headerContent: FC;
}) => {
  const {
    children,
    drawerContent: DrawerContent,
    headerContent: HeaderContent,
  } = props;

  const { handleDrawerToggle, drawerOpen, drawerWidth } =
    useContext(DrawerContext);

  return (
    <LayoutComponent
      drawerOpen={drawerOpen}
      handleDrawerToggle={handleDrawerToggle}
      drawerWidth={drawerWidth}
      drawerContent={DrawerContent}
      headerContent={HeaderContent}
    >
      {children}
    </LayoutComponent>
  );
};
