import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { ReactNode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { amplifyConfig } from "./configs/amplify";
import { BgwProvider } from "./contexts/backend_gateway/provider";
import { JoyThemed } from "./frameworks/joy/joy_themed";
import { ProfilePage } from "./pages/profile";
import { WelcomePage } from "./pages/welcome";
import { OrganizationsPage } from "./pages/organizations";
import { EditOrganizationPane } from "./pages/organizations/edit_organization";
import { DeploymentStepsPage } from "./pages/deployment_steps";
import { ServiceStatusesPage } from "./pages/service_statuses";
import { OnboarderContainer } from "./components/onboarder/container";
import { AlertsProvider } from "./contexts/alerts/provider";
import { Layout } from "./layouts/drawer_layout/layout";
import { DashboardPage } from "./pages/dashboard";
import { MyTeamPage } from "./pages/my_team";
// import { OrganizationSetupPage } from "./pages/organization_setup";
import { EditUserPane } from "./pages/my_team/edit_user";
import { AuthenticatorPage } from "./pages/authenticator";
import { SetOrganizationPage } from "./pages/set_organization";
import { MyEnvironmentPage } from "./pages/my_environment";
import { MyEnvironmentHelpPane } from "./pages/my_environment/help_pane";
import { FeaturesProvider } from "./contexts/features/provider";
import { MyAnyStackPage } from "./pages/my_any_stack";
import { EnvironmentsProvider } from "./contexts/environments/provider";
import { QuickLaunchPage } from "./pages/quick_launch";
import { QuickLaunchHelpPane } from "./pages/quick_launch/help_pane";

Amplify.configure(amplifyConfig);

const { Provider: AuthenticatorProvider } = Authenticator;

const AuthCheck = (props: {
  loading: ReactNode;
  toAuthentiacte: ReactNode;
  authenticated: ReactNode;
}) => {
  const { loading, toAuthentiacte, authenticated } = props;
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  if (authStatus === "configuring") {
    return loading;
  } else if (authStatus !== "authenticated") {
    return toAuthentiacte;
  }
  return authenticated;
};

const App = () => {
  return (
    <AuthenticatorProvider>
      <AuthCheck
        loading={<div>Loading...</div>}
        toAuthentiacte={<AuthenticatorPage />}
        authenticated={
          <BgwProvider>
            <AlertsProvider>
              <FeaturesProvider>
                <EnvironmentsProvider>
                  <BrowserRouter>
                    <JoyThemed>
                      <OnboarderContainer
                        onboardingComponent={<QuickLaunchPage />}
                        selectCompanyComponent={<SetOrganizationPage />}
                      >
                        <Routes>
                          {/* <Route
                            path="/onboarding/"
                            element={<OrganizationSetupPage />}
                          />
                          <Route
                            path="/onboarding/:deploymentId/:stepId"
                            element={<OrganizationSetupPage />}
                          /> */}
                          <Route
                            path="/quick_launch"
                            element={<QuickLaunchPage />}
                          />
                          <Route
                            path="/quick_launch/:serviceType"
                            element={<QuickLaunchPage />}
                          />
                          <Route
                            path="/quick_launch/:serviceType/:deploymentId"
                            element={<QuickLaunchPage />}
                          >
                            <Route
                              path="help/:helpId"
                              element={<QuickLaunchHelpPane />}
                            />
                          </Route>
                          <Route path="/" element={<Layout />}>
                            <Route index element={<WelcomePage />} />
                            <Route
                              path="/dashboard/:deploymentId"
                              element={<DashboardPage />}
                            />
                            <Route
                              path="/deployment_steps"
                              element={<DeploymentStepsPage />}
                            />
                            <Route
                              path="/deployment_steps/:deploymentId/:stepId"
                              element={<DeploymentStepsPage />}
                            />
                            <Route
                              path="/my_environment"
                              element={<MyEnvironmentPage />}
                            />
                            <Route
                              path="/my_environment/:deploymentId"
                              element={<MyEnvironmentPage />}
                            >
                              <Route
                                path="help/:helpId"
                                element={<MyEnvironmentHelpPane />}
                              />
                            </Route>
                            <Route
                              path="/service_statuses"
                              element={<ServiceStatusesPage />}
                            />
                            <Route
                              path="/service_statuses/:stackId"
                              element={<ServiceStatusesPage />}
                            />
                            <Route path="/my_team" element={<MyTeamPage />}>
                              <Route
                                path=":userId"
                                element={<EditUserPane />}
                              />
                            </Route>
                            <Route
                              path="/organizations"
                              element={<OrganizationsPage />}
                            >
                              <Route
                                path=":organizationId"
                                element={<EditOrganizationPane />}
                              />
                            </Route>
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route
                              path="/my_any_stack"
                              element={<MyAnyStackPage />}
                            />
                          </Route>
                        </Routes>
                      </OnboarderContainer>
                    </JoyThemed>
                  </BrowserRouter>
                </EnvironmentsProvider>
              </FeaturesProvider>
            </AlertsProvider>
          </BgwProvider>
        }
      />
    </AuthenticatorProvider>
  );
};

export default App;
