import { useParams } from "react-router-dom";
import { PageWithRightPane } from "../../layouts/page_with_right_pane";
import { MyEnvironmentContainer } from "./container";
import { AddMyEnvironmentContainer } from "./add/container";

export const MyEnvironmentPage = () => {
  const params = useParams();
  const { deploymentId } = params;

  return (
    <PageWithRightPane>
      {deploymentId ? (
        <MyEnvironmentContainer deploymentId={deploymentId!} />
      ) : (
        <AddMyEnvironmentContainer />
      )}
    </PageWithRightPane>
  );
};
