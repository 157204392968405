import "@fontsource/share-tech-mono";
import Typography from "@mui/joy/Typography";
import { ReactNode } from "react";

export const PageTitle = (props: { children?: ReactNode; sx?: any }) => {
  const { sx, ...rest } = props;
  return (
    <Typography
      sx={[
        {
          fontSize: {
            xs: "22px",
            sm: "35px",
          },
          // fontFamily: "'Share Tech Mono'",
          fontWeight: 100,
          color: "neutral.100",
          textTransform: "uppercase",
          letterSpacing: ".4rem",
          mb: 2,
        },
        {
          ...(sx || {}),
        },
      ]}
      {...rest}
    />
  );
};
