import { useContext } from "react";
import { EnvFeaturesComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { EnvironmentsContext } from "../../../contexts/environments/context";
import {
  StackStatus,
  useGetMyHostedZonesQuery,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../../contexts/alerts/type";

export const EnvFeaturesContainer = (props: {
  deploymentId: string;
  navigateToHelp: (stepId: string) => void;
  envFeaturesStackStatus: StackStatus | null;
  updateEnvFeaturesStack: (
    deploymentId: string,
    fullDomainName: string,
    emailNotificationList: string,
    logRetentionDays: number,
    restApiV1Enabled: boolean,
    restApiV1Subdomain: string,
    httpApiV2Enabled: boolean,
    httpApiV2Subdomain: string,
    vpcEnabled: boolean,
    vpcCidr: string,
    primaryPrivateCidr: string,
    secondaryPrivateCidr: string,
    primaryPublicCidr: string,
    secondaryPublicCidr: string,
    cognitoEnabled: boolean,
    cognitoUserPoolEnabled: boolean,
    cognitoAllowAdminCreateUserOnly: boolean,
    cognitoAlertsEnabled: boolean,
    cognitoOidcEnabled: boolean,
    cognitoOidcUrl: string,
    cognitoOidcClientId: string,
    cognitoOidcThumbprint: string,
    callback: null | (() => void)
  ) => void;
}) => {
  const {
    deploymentId,
    navigateToHelp,
    envFeaturesStackStatus,
    updateEnvFeaturesStack,
  } = props;

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const { loading: envLoading } = useContext(EnvironmentsContext);

  const envFeaturesParamsJson = envFeaturesStackStatus?.parametersJSON || null;

  const envFeaturesStatus = envFeaturesStackStatus?.status || null;

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const { data: getMyHostedZonesData, loading: getMyHostedZonesLoading } =
    useGetMyHostedZonesQuery({
      client: bgwService.getClient(),
      variables: {
        deploymentId,
      },
      fetchPolicy: "network-only",
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  return (
    <EnvFeaturesComponent
      loading={envLoading || getMyHostedZonesLoading || whoAmILoading}
      deploymentId={deploymentId}
      envFeaturesParamsJson={envFeaturesParamsJson}
      envFeaturesStatus={envFeaturesStatus}
      hostedZones={getMyHostedZonesData?.getMyHostedZones || []}
      updateEnvFeaturesStack={updateEnvFeaturesStack}
      navigateToHelp={() => navigateToHelp("env_features")}
      myEmail={whoAmIData?.whoAmI?.email || ""}
    />
  );
};
