import Box from "@mui/joy/Box";
import { ReactNode } from "react";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../drawer_layout/layout/constants";

export const Page = (props: { children?: ReactNode; sx?: any }) => {
  return (
    <Box
      sx={{
        p: 0,
        m: 0,
        pt: {
          xs: `${HEADER_HEIGHT}px`,
          sm: `${HEADER_HEIGHT}px`,
          md: `${HEADER_HEIGHT}px`,
          lg: 0,
        },
        boxSizing: "border-box",
        display: {
          xs: "inherit",
          md: "inherit",
        },
        gridRow: 1,
        gridColumn: {
          xs: 1,
          sm: "1 / span 2",
          md: "2 / span 2",
        },
        height: {
          xs: `calc(100vh - ${FOOTER_HEIGHT}px)`,
          sm: "100vh",
        },
        overflow: "auto",
        bgcolor: "neutral.900",
      }}
      aria-label="main-content"
      {...props}
    />
  );
};
