import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import { PageLoading } from "../../../components/page_loading";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { awsRegions } from "../../../configs/aws_regions";

export const AwsConnectionComponent = (props: {
  loading: boolean;
  accountId: string | null;
  awsRegion: string | null;
  updateAccountId: (accountId: string) => void;
  updateAwsRegion: (awsRegion: string) => void;
  deployment: Deployment | null;
  downloadCfnJsonBlob: () => void;
  connectionWorks: boolean | null;
  checkConnection: () => void;
  navigateToHelp: null | (() => void);
}) => {
  const {
    loading,
    accountId,
    awsRegion,
    updateAccountId,
    updateAwsRegion,
    deployment,
    downloadCfnJsonBlob,
    connectionWorks,
    checkConnection,
    navigateToHelp,
  } = props;
  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="body-sm" sx={{ py: 1 }}>
            Waffle enables AWS code deployment by creating an IAM role in your
            account. This role allows Waffle to manage resources. You can
            download the role definition below and install it using AWS
            CloudFormation.{" "}
            <Link onClick={() => navigateToHelp && navigateToHelp()}>
              Read more about creating AWS accounts
            </Link>
          </Typography>
        </Grid>

        <Grid xs={12}>
          <Typography level="body-lg" sx={{ py: 2 }}>
            Step 1: Download the CloudFormation Template
          </Typography>
          <Button
            startDecorator={<DownloadIcon />}
            onClick={downloadCfnJsonBlob}
            disabled={loading}
          >
            Download template
          </Button>
        </Grid>
        <Grid xs={12}>
          <Typography level="body-lg" sx={{ py: 2 }}>
            Step 2: Deploy the CloudFormation Template
          </Typography>
          <Typography level="body-sm">
            Go to{" "}
            <Link
              href="https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create"
              endDecorator={<OpenInNewIcon />}
              target="_blank"
            >
              CloudFormation in the AWS Console
            </Link>{" "}
            and create a stack with the template you downloaded.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography level="body-lg" sx={{ py: 2 }}>
            Step 3: Test and store the connection
          </Typography>
        </Grid>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>Account Id</FormLabel>
            <Input
              value={accountId || ""}
              disabled={(deployment?.accountId || null) !== null}
              onChange={(event) => {
                updateAccountId(event.target.value);
              }}
            />
            <FormHelperText>
              The ID of the AWS account you deployed the IAM role into.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>AWS Region</FormLabel>
            <Select
              value={awsRegion || ""}
              disabled={(deployment?.awsRegion || null) !== null}
            >
              {awsRegions.map((region) => (
                <Option
                  key={region}
                  value={region}
                  onClick={() => {
                    updateAwsRegion(region);
                  }}
                >
                  {region}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              The AWS region where you want your environment to be deployed.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>Connect from Waffle</FormLabel>
            {connectionWorks !== true ? (
              <>
                <Button
                  disabled={loading || !awsRegion || !accountId}
                  onClick={checkConnection}
                >
                  Connect
                </Button>
                {connectionWorks === false && (
                  <FormHelperText sx={{ pt: 1 }}>
                    No connection yet
                  </FormHelperText>
                )}
              </>
            ) : (
              <Chip
                variant="solid"
                color="success"
                size="md"
                sx={{ mt: 1 }}
                startDecorator={<CheckCircleIcon />}
              >
                Connection works
              </Chip>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
