import { gql } from "@apollo/client";
import { useContext, useEffect } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  GetMyGitConnectionsDocument,
  useCreateMyGitConnectionMutation,
  useGetMyGitConnectionsQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { GitConnectionsComponent } from "./component";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";

export const GitConnectionsContainer = (props: {
  deploymentId: string;
  navigateToHelp: (stepId: string) => void;
}) => {
  const { deploymentId, navigateToHelp } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: getMyGitConnectionsData,
    loading: getMyGitConnectionsLoading,
    refetch: getMyGitConnectionsRefetch,
  } = useGetMyGitConnectionsQuery({
    client: bgwService.getClient(),
    fetchPolicy: "network-only",
    variables: {
      deploymentId,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });
  const [
    createMyGitConnectionMutation,
    { loading: createMyGitConnectionLoading },
  ] = useCreateMyGitConnectionMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const createGitConnection = (name: string, providerType: string) => {
    createMyGitConnectionMutation({
      variables: {
        gitConnectionInput: {
          deploymentId,
          name,
          providerType,
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            getMyGitConnections(existingGitConnections = []) {
              const newGitConnectionsRef = cache.writeFragment({
                data: data?.createMyGitConnection,
                fragment: gql`
                  fragment NewGitConnection on GitConnection {
                    id
                    name
                    connectionStatus
                    ownerAccountId
                    providerType
                    pendingConnectionLink
                  }
                `,
              });
              return [...existingGitConnections, newGitConnectionsRef];
            },
          },
        });
      },
      refetchQueries: [
        {
          query: GetMyGitConnectionsDocument,
          variables: {
            deploymentId,
          },
        },
      ],
      onCompleted: (data) => {
        if (data?.createMyGitConnection !== undefined) {
          // ...
        }
      },
    });
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      if (
        getMyGitConnectionsData?.getMyGitConnections
          ?.map((c) => c.connectionStatus)
          .includes("PENDING")
      ) {
        getMyGitConnectionsRefetch();
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [getMyGitConnectionsData, getMyGitConnectionsRefetch]);

  return (
    <GitConnectionsComponent
      loading={getMyGitConnectionsLoading || createMyGitConnectionLoading}
      createGitConnection={createGitConnection}
      connections={getMyGitConnectionsData?.getMyGitConnections || []}
      navigateToHelp={() => navigateToHelp("git_connections")}
    />
  );
};
