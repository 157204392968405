import { ReactNode, useContext } from "react";
import { FeaturesContext } from "./context";
import { FeaturesType } from "./type";
import { useGetMyOrganizationQuery } from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertsContext } from "../alerts/context";
import { BgwContext } from "../backend_gateway/context";
import { AlertType } from "../alerts/type";

const { Provider } = FeaturesContext;

export const FeaturesProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  const { addAlert } = useContext(AlertsContext);
  const { bgwService } = useContext(BgwContext);

  const { data: organizationData, loading: organizationLoading } =
    useGetMyOrganizationQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const value: FeaturesType = {
    loading: organizationLoading,
    workInProgressFeaturesEnabled:
      organizationData?.getMyOrganization?.features
        ?.workInProgressFeaturesEnabled || false,
    envFeaturesStackEnabled:
      organizationData?.getMyOrganization?.features?.envFeaturesStackEnabled ||
      false,
    singleDeployment:
      organizationData?.getMyOrganization?.features?.singleDeployment || false,
    databaseEnabled:
      organizationData?.getMyOrganization?.features?.databaseEnabled || false,
    apiV1Enabled:
      organizationData?.getMyOrganization?.features?.apiV1Enabled || false,
    wsApiEnabled:
      organizationData?.getMyOrganization?.features?.wsApiEnabled || false,
    httpApiEnabled:
      organizationData?.getMyOrganization?.features?.httpApiEnabled || false,
    customDomainEnabled:
      organizationData?.getMyOrganization?.features?.customDomainEnabled ||
      false,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
