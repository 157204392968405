import { gql } from "@apollo/client";
import { AlertType } from "../alerts/type";
import { BgwContext } from "../backend_gateway/context";
import { AlertsContext } from "../alerts/context";
import {
  TestMyWaffleRoleDocument,
  useAssignMyDeploymentAccountAndRegionMutation,
  useCreateMyDeploymentMutation,
  useEditMyDeploymentMutation,
  useGetMyDeploymentsQuery,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { EnvironmentsContext } from "./context";
import { EnvironmentsType } from "./type";
import { ReactNode, useContext, useState } from "react";

const { Provider } = EnvironmentsContext;

export const EnvironmentsProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    refetch: refetchMyDeployments,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [createDeploymentLoading, setCreateDeploymentLoading] =
    useState<boolean>(false);
  const [createMyDeployment, { loading: createMyDeploymentLoading }] =
    useCreateMyDeploymentMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        setCreateDeploymentLoading(false);
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const addDeployment = (
    deploymentId: string | null,
    callback: null | ((deploymentId: string) => void) = null
  ) => {
    setCreateDeploymentLoading(true);
    createMyDeployment({
      variables: { deploymentId },
      optimisticResponse: {
        createMyDeployment: {
          __typename: "Deployment",
          id: deploymentId || "new",
          name: deploymentId || "",
          accountId: "",
          awsRegion: "",
          version: "EnvFeatures",
          defaultLogRetentionDays: 365,
          defaultAlarmsEnabled: true,
          defaultDatabaseRetention: 35,
          defaultRequireCicdManualApproval: false,
          fullDomainName: "",
          waffleDomain: "",
        },
      },
      update(cache, { data }) {
        // if the deployment exists then the cache needs no update
        if (!data || !data?.createMyDeployment) return;
        cache.modify({
          fields: {
            getMyDeployments(existingDeployments = []) {
              const newDeploymentRef = cache.writeFragment({
                data: {
                  ...data?.createMyDeployment,
                },
                fragment: gql`
                  fragment NewDeployment on Deployment {
                    id
                    name
                    accountId
                    awsRegion
                    version
                    defaultLogRetentionDays
                    defaultAlarmsEnabled
                    defaultDatabaseRetention
                    defaultRequireCicdManualApproval
                    fullDomainName
                    waffleDomain
                  }
                `,
              });
              return [...existingDeployments, newDeploymentRef];
            },
          },
        });
      },
      refetchQueries: ["GetMyDeployments"],
      onCompleted: (data) => {
        setCreateDeploymentLoading(false);
        callback?.(data?.createMyDeployment?.id || "");
      },
    });
  };

  const [editDeploymentLoading, setEditDeploymentLoading] =
    useState<boolean>(false);

  const [editMyDeployment, { loading: editMyDeploymentLoading }] =
    useEditMyDeploymentMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        setEditDeploymentLoading(false);
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
      onCompleted: () => {
        setEditDeploymentLoading(false);
      },
      update(cache, { data }) {
        if (!data || !data?.editMyDeployment) return;

        // Write a fragment for the updated deployment
        const updatedDeploymentRef = cache.writeFragment({
          data: data.editMyDeployment,
          fragment: gql`
            fragment UpdatedDeployment on Deployment {
              id
              name
            }
          `,
        });

        cache.modify({
          fields: {
            getMyDeployments(existingDeployments = [], { readField }) {
              return existingDeployments.map((deploymentRef: any) =>
                readField("id", deploymentRef) === data.editMyDeployment!.id
                  ? updatedDeploymentRef
                  : deploymentRef
              );
            },
          },
        });
      },
    });

  const editDeployment = (deploymentId: string, deploymentName: string) => {
    setEditDeploymentLoading(true);
    editMyDeployment({
      variables: {
        deploymentInput: { id: deploymentId, name: deploymentName },
      },
    });
  };

  const [
    assignDeploymentAccountAndRegionLoading,
    setAssignDeploymentAccountAndRegionLoading,
  ] = useState<boolean>(false);

  const [
    assignMyDeploymentAccountAndRegion,
    { loading: assignMyDeploymentAccountAndRegionLoading },
  ] = useAssignMyDeploymentAccountAndRegionMutation({
    client: bgwService.getClient(),
    onError: (error: any) => {
      setAssignDeploymentAccountAndRegionLoading(false);
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
    update(cache, { data }) {
      if (!data || !data?.assignMyDeploymentAccountAndRegion) return;

      // Write a fragment for the updated deployment
      const updatedDeploymentRef = cache.writeFragment({
        data: data.assignMyDeploymentAccountAndRegion,
        fragment: gql`
          fragment UpdatedDeployment on Deployment {
            id
            accountId
            awsRegion
          }
        `,
      });

      cache.modify({
        fields: {
          getMyDeployments(existingDeployments = [], { readField }) {
            return existingDeployments.map((deploymentRef: any) =>
              readField("id", deploymentRef) ===
              data.assignMyDeploymentAccountAndRegion!.id
                ? updatedDeploymentRef
                : deploymentRef
            );
          },
        },
      });
    },
  });

  const assignDeploymentAccountAndRegion = async (
    deploymentId: string,
    accountId: string,
    awsRegion: string
  ) => {
    const deployment = getMyDeploymentsData?.getMyDeployments?.find(
      (deployment) => deployment.id === deploymentId
    );
    if (
      !deployment ||
      deployment?.accountId !== "" ||
      deployment?.awsRegion !== ""
    ) {
      return;
    }
    setAssignDeploymentAccountAndRegionLoading(true);
    await assignMyDeploymentAccountAndRegion({
      variables: { deploymentId, accountId, awsRegion },
      onCompleted: () => {
        setAssignDeploymentAccountAndRegionLoading(false);
      },
      refetchQueries: [
        {
          query: TestMyWaffleRoleDocument,
          variables: { deploymentId },
        },
      ],
      optimisticResponse: {
        assignMyDeploymentAccountAndRegion: {
          id: deploymentId,
          accountId: accountId,
          awsRegion: awsRegion,
        },
      },
    });
  };

  const value: EnvironmentsType = {
    loading:
      getMyDeploymentsLoading ||
      createDeploymentLoading ||
      createMyDeploymentLoading ||
      editDeploymentLoading ||
      editMyDeploymentLoading ||
      assignDeploymentAccountAndRegionLoading ||
      assignMyDeploymentAccountAndRegionLoading,
    deployments: getMyDeploymentsData?.getMyDeployments || [],
    reFetchDeployments: () => refetchMyDeployments(),
    addDeployment,
    editDeployment,
    assignDeploymentAccountAndRegion,
  };
  return <Provider value={value}>{props.children}</Provider>;
};
