import { useNavigate, useParams } from "react-router-dom";
import { AwsConnectionHelpPane } from "./aws_connection/help_pane";
import { GitConnectionsHelpPane } from "./git_connections/help_pane";
import { FullDomainNamesHelpPane } from "./full_domain_names/help_pane";

const HelpMap: Record<
  string,
  React.ComponentType<{ navigateBack: () => void }>
> = {
  aws_connection: AwsConnectionHelpPane,
  git_connections: GitConnectionsHelpPane,
  full_domain_names: FullDomainNamesHelpPane,
};

export const MyEnvironmentHelpPane = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { deploymentId, helpId } = params;
  if (deploymentId === undefined) return null;
  const HelpComponent = HelpMap[helpId!];
  if (HelpComponent === undefined) return null;
  const navigateBack = () => {
    navigate(`/my_environment/${deploymentId}`);
  };

  return <HelpComponent navigateBack={navigateBack} />;
};
