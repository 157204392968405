import Box from "@mui/joy/Box";
import { ReactNode } from "react";
import { HEADER_HEIGHT } from "../constants";
import { useTheme } from "@mui/joy";

export const FloatingHeader = (props: {
  drawerWidth: number;
  children?: ReactNode;
  sx?: any;
}) => {
  const { drawerWidth, ...rest } = props;
  const theme = useTheme();
  return (
    <Box
      {...rest}
      sx={[
        {
          p: 1,
          m: 0,
          boxSizing: "border-box",
          gap: 2,
          position: "fixed",
          top: 0,
          right: 0,
          height: HEADER_HEIGHT,
          left: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: drawerWidth,
          },
          display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
          },
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          background: `background.backdrop`,
          boxShadow: "none",
          backdropFilter: "blur(8px)",
          borderBottom: `1px solid`,
          borderBottomColor: "neutral.900",
          zIndex: 1100,
        },
        {
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1099,
            background: `linear-gradient(to top, transparent, ${theme.vars.palette.neutral[900]})`,
          },
          "&:after": {
            content: '""',
            position: "absolute",
            top: HEADER_HEIGHT,
            right: 0,
            height: "1px",
            left: 0,
            zIndex: 1101,
            background: "background.backdrop",
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
};
