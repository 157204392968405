import { useNavigate, useParams } from "react-router-dom";
import { QuickLaunchContainer } from "./container";
import { ServiceType } from "./service_types";
import { SelectServiceTypePage } from "./select_service_type";
import { LeanLayout } from "./layout";
import { useContext, useEffect, useRef } from "react";
import { EnvironmentsContext } from "../../contexts/environments/context";

export const QuickLaunchPage = () => {
  const params = useParams();
  const { serviceType, deploymentId } = params;

  const {
    loading: envLoading,
    addDeployment,
    deployments,
  } = useContext(EnvironmentsContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (deploymentId === undefined && serviceType !== undefined) {
      navigate(`/quick_launch/${serviceType}/prod`, { replace: true });
    }
  }, [deploymentId, serviceType, navigate]);

  const deploymentAddedMutationInProgress = useRef(false);

  useEffect(() => {
    const deploymentIds = deployments.map((deployment) => deployment.id);
    if (
      deploymentId !== undefined &&
      !deploymentIds.includes(deploymentId as string) &&
      !deploymentAddedMutationInProgress.current
    ) {
      deploymentAddedMutationInProgress.current = true;
      addDeployment(deploymentId, () => {
        // NOTE: it doesn't have to enable adding more deployments here
        // deploymentAddedMutationInProgress.current = false;
      });
    }
  }, [
    deploymentId,
    deployments,
    addDeployment,
    deploymentAddedMutationInProgress,
  ]);

  if (serviceType === undefined) return <SelectServiceTypePage />;

  const serviceTypeEnum = ServiceType[serviceType as keyof typeof ServiceType];
  if (serviceTypeEnum === undefined) return <SelectServiceTypePage />;

  if (deploymentId === undefined) return null;

  return (
    <LeanLayout>
      <QuickLaunchContainer
        loading={envLoading}
        deploymentId={deploymentId!}
        serviceType={serviceTypeEnum!}
      />
    </LeanLayout>
  );
};
