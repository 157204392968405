import { useContext, useState } from "react";
import { FullDomainNamesComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { EnvironmentsContext } from "../../../contexts/environments/context";
import {
  useCreateMyHostedZoneWithCertMutation,
  useCreateMyWaffleFullDomainNameHostedZoneMutation,
  useCreateMyWaffleFullDomainNameNsRecordMutation,
  useGetMyCertificateCnameRecordStatusLazyQuery,
  useGetMyHostedZoneChangeStatusLazyQuery,
  useGetMyHostedZonesQuery,
  useGetMyWaffleFullDomainNameNsRecordStatusLazyQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../../contexts/alerts/type";
import { FeaturesContext } from "../../../contexts/features/context";

export const FullDomainNamesContainer = (props: {
  deploymentId: string;
  navigateToHelp: (stepId: string) => void;
}) => {
  const { deploymentId, navigateToHelp } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const { loading: envLoading, deployments } = useContext(EnvironmentsContext);
  const { customDomainEnabled } = useContext(FeaturesContext);

  const deployment = deployments.find((d) => d.id === deploymentId);

  const {
    data: getMyHostedZonesData,
    loading: getMyHostedZonesLoading,
    refetch: getMyHostedZonesRefetch,
  } = useGetMyHostedZonesQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [createHostedZoneLoading, setCreateHostedZoneLoading] = useState(false);

  const [
    createMyHostedZoneWithCert,
    { loading: createMyHostedZoneWithCertLoading },
  ] = useCreateMyHostedZoneWithCertMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      setCreateHostedZoneLoading(false);
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyHostedZoneChangeStatus,
    { loading: getMyHostedZoneChangeStatusLoading },
  ] = useGetMyHostedZoneChangeStatusLazyQuery({
    client: bgwService.getClient(),
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [hostedZoneStatuses, setHostedZoneStatuses] = useState<
    Record<string, string | null>
  >({});

  const pollHostedZoneChangeStatus = (
    domainName: string,
    changeId: string,
    callback: () => void
  ) => {
    getMyHostedZoneChangeStatus({
      variables: {
        deploymentId,
        changeInfoId: changeId,
      },
      onCompleted: (data) => {
        const changeStatus = data?.getMyHostedZoneChangeStatus;
        if (changeStatus) {
          setHostedZoneStatuses((prev) => ({
            ...prev,
            [domainName]: changeStatus,
          }));
        }

        // console.log(`Change status for ${domainName}: ${changeStatus}`);
        if (changeStatus !== "INSYNC") {
          setTimeout(
            () => pollHostedZoneChangeStatus(domainName, changeId, callback),
            3000
          );
        } else {
          // console.log(`Hosted zone change for ${domainName} is now INSYNC.`);
          getMyHostedZonesRefetch();
          callback();
        }
      },
      // Optionally, retry on error.
      onError: (error) => {
        setTimeout(
          () => pollHostedZoneChangeStatus(domainName, changeId, callback),
          15000
        );
      },
    });
  };

  const [
    getMyCertificateCnameRecordStatus,
    { loading: getMyCertificateCnameRecordStatusLoading },
  ] = useGetMyCertificateCnameRecordStatusLazyQuery({
    client: bgwService.getClient(),
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [certDnsStatuses, setCertDnsStatuses] = useState<
    Record<string, string | null>
  >({});

  const pollCertDnsChangeStatus = (
    domainName: string,
    changeId: string,
    callback: () => void
  ) => {
    getMyCertificateCnameRecordStatus({
      variables: {
        deploymentId,
        changeInfoId: changeId,
      },
      onCompleted: (data) => {
        const changeStatus = data?.getMyCertificateCnameRecordStatus;
        if (changeStatus) {
          setCertDnsStatuses((prev) => ({
            ...prev,
            [domainName]: changeStatus,
          }));
        }

        // console.log(`Change status for ${domainName}: ${changeStatus}`);
        if (changeStatus !== "INSYNC") {
          setTimeout(
            () => pollCertDnsChangeStatus(domainName, changeId, callback),
            3000
          );
        } else {
          // console.log(`Hosted zone change for ${domainName} is now INSYNC.`);
          // getMyCertificateCnameRecordStatusRefetch();
          callback();
        }
      },
      // Optionally, retry on error.
      onError: (error) => {
        setTimeout(
          () => pollCertDnsChangeStatus(domainName, changeId, callback),
          15000
        );
      },
    });
  };

  const createHostedZone = (domainName: string) => {
    if (!customDomainEnabled) return;
    setCreateHostedZoneLoading(true);
    setHostedZoneStatuses((prev) => ({
      ...prev,
      [domainName]: "IN_PROGRESS",
    }));
    createMyHostedZoneWithCert({
      variables: {
        deploymentId,
        fullDomainName: domainName,
      },
      onCompleted: (data) => {
        setCreateHostedZoneLoading(false);
        const result = data?.createMyHostedZoneWithCert || null;
        if (!result) {
          return;
        }
        const { hostedZoneChangeInfoId, certDnsChangeInfoId } = result;
        if (
          hostedZoneChangeInfoId !== null &&
          hostedZoneChangeInfoId !== undefined
        ) {
          pollHostedZoneChangeStatus(domainName, hostedZoneChangeInfoId, () => {
            setHostedZoneStatuses((prev) => ({
              ...prev,
              [domainName]: "COMPLETED",
            }));
          });
        }
        if (certDnsChangeInfoId !== null && certDnsChangeInfoId !== undefined) {
          pollCertDnsChangeStatus(domainName, certDnsChangeInfoId, () => {
            setCertDnsStatuses((prev) => ({
              ...prev,
              [domainName]: "COMPLETED",
            }));
          });
        }
      },
    });
  };

  const [
    createWaffleDomainHostedZoneLoading,
    setCreateWaffleDomainHostedZoneLoading,
  ] = useState(false);

  const [waffleDomainHostedZoneStatus, setWaffleDomainHostedZoneStatus] =
    useState<string | null>(null);

  const [
    createMyWaffleDomainHostedZone,
    { loading: createMyWaffleDomainHostedZoneLoading },
  ] = useCreateMyWaffleFullDomainNameHostedZoneMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      setCreateWaffleDomainHostedZoneLoading(false);
      setWaffleDomainHostedZoneStatus("FAILED");
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const createWaffleDomainHostedZone = () => {
    setCreateWaffleDomainHostedZoneLoading(true);
    setWaffleDomainHostedZoneStatus("IN_PROGRESS");
    createMyWaffleDomainHostedZone({
      variables: {
        deploymentId,
      },
      onCompleted: (data) => {
        setCreateWaffleDomainHostedZoneLoading(false);
        // setWaffleDomainHostedZoneStatus("COMPLETED");
        const changeInfoId = data?.createMyWaffleFullDomainNameHostedZone;
        // setWaffleDomainHostedZoneChangeInfoId(changeInfoId || null);
        if (changeInfoId && deployment?.waffleDomain) {
          pollHostedZoneChangeStatus(
            deployment.waffleDomain,
            changeInfoId,
            () => setWaffleDomainHostedZoneStatus("COMPLETED")
          );
        }
      },
    });
  };

  const [
    createWaffleDomainNsRecordLoading,
    setCreateWaffleDomainNsRecordLoading,
  ] = useState(false);

  const [waffleDomainNsRecordsStatus, setWaffleDomainNsRecordsStatus] =
    useState<string | null>(null);

  const [
    createMyWaffleDomainNsRecord,
    { loading: createMyWaffleDomainNsRecordLoading },
  ] = useCreateMyWaffleFullDomainNameNsRecordMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      setCreateWaffleDomainNsRecordLoading(false);
      setWaffleDomainNsRecordsStatus("FAILED");
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyWaffleFullDomainNameNsRecordStatus,
    { loading: getMyWaffleFullDomainNameNsRecordStatusLoading },
  ] = useGetMyWaffleFullDomainNameNsRecordStatusLazyQuery({
    client: bgwService.getClient(),
    fetchPolicy: "network-only",
  });

  const pollWaffleDomainNsRecordStatus = (changeId: string) => {
    getMyWaffleFullDomainNameNsRecordStatus({
      variables: {
        deploymentId,
        changeInfoId: changeId,
      },
      onCompleted: (data) => {
        const nsRecordStatus = data?.getMyWaffleFullDomainNameNsRecordStatus;
        if (nsRecordStatus !== "INSYNC") {
          setTimeout(() => pollWaffleDomainNsRecordStatus(changeId), 3000);
        } else {
          setWaffleDomainNsRecordsStatus("COMPLETED");
          console.log("NS Record status is now INSYNC.");
        }
      },
      // Optionally handle error and/or retry.
      // onError: (error) => {
      //   console.error("Error polling NS record status:", error);
      //   setTimeout(() => pollWaffleDomainNsRecordStatus(changeId), 3000);
      // },
    });
  };

  const createWaffleDomainNsRecord = () => {
    setCreateWaffleDomainNsRecordLoading(true);
    setWaffleDomainNsRecordsStatus("IN_PROGRESS");
    createMyWaffleDomainNsRecord({
      variables: {
        deploymentId,
      },
      onCompleted: (data) => {
        setCreateWaffleDomainNsRecordLoading(false);
        const changeInfoId = data?.createMyWaffleFullDomainNameNsRecord || null;
        if (changeInfoId !== null) {
          pollWaffleDomainNsRecordStatus(changeInfoId);
        }
      },
    });
  };

  return (
    <FullDomainNamesComponent
      loading={
        envLoading ||
        getMyHostedZonesLoading ||
        createHostedZoneLoading ||
        createMyHostedZoneWithCertLoading ||
        getMyHostedZoneChangeStatusLoading ||
        getMyCertificateCnameRecordStatusLoading ||
        createWaffleDomainHostedZoneLoading ||
        createMyWaffleDomainHostedZoneLoading ||
        createWaffleDomainNsRecordLoading ||
        createMyWaffleDomainNsRecordLoading ||
        getMyWaffleFullDomainNameNsRecordStatusLoading
      }
      deployment={deployment || null}
      customDomainEnabled={customDomainEnabled}
      existingHostedZones={getMyHostedZonesData?.getMyHostedZones || []}
      createHostedZone={createHostedZone}
      hostedZoneStatuses={hostedZoneStatuses}
      certDnsStatuses={certDnsStatuses}
      waffleDomain={deployment?.waffleDomain || null}
      createWaffleDomainHostedZone={createWaffleDomainHostedZone}
      waffleDomainHostedZoneStatus={waffleDomainHostedZoneStatus}
      createWaffleDomainNsRecord={createWaffleDomainNsRecord}
      waffleDomainNsRecordsStatus={waffleDomainNsRecordsStatus}
      navigateToHelp={() => navigateToHelp("full_domain_names")}
    />
  );
};
