import DomainIcon from "@mui/icons-material/Domain";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { PageLoading } from "../../components/page_loading";
import { LeanLayout } from "./layout";

export const SetOrganizationComponent = (props: {
  loading: boolean;
  setOrganizationName: (organizationName: string) => Promise<void>;
}) => {
  const { loading, setOrganizationName: storeOrganizationName } = props;

  const [organizationName, setOrganizationName] = useState<string>("");

  const validId =
    organizationName === "" || /^[a-z,A-Z,0-9, ]+$/.test(organizationName);
  return (
    <LeanLayout>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="h2" sx={{ pb: 2 }}>
            Tell us about your organization
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            Waffle is designed to be used by teams. You can invite your team
            members to join your organization.
          </Typography>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>The unique name of your organization</FormLabel>
            <Stack spacing={0.5}>
              <Input
                startDecorator={<DomainIcon />}
                endDecorator={
                  <Button
                    onClick={() => storeOrganizationName(organizationName)}
                    disabled={!validId || loading || organizationName === ""}
                  >
                    Save
                  </Button>
                }
                placeholder="My Organization"
                value={organizationName}
                onChange={(event) => setOrganizationName(event.target.value)}
              />
              <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                {validId ? "" : "Invalid value"}
              </Typography>
            </Stack>
          </FormControl>
        </Grid>
      </Grid>
    </LeanLayout>
  );
};
