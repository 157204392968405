import {
  CssVarsProvider,
  useColorScheme,
} from "@mui/joy/styles/CssVarsProvider";
import { ReactNode } from "react";
import { theme } from "./theme";

const DarkMode = (props: { children?: ReactNode }): ReactNode => {
  const { children } = props;
  const { setMode } = useColorScheme();
  setMode("dark");
  return children;
};

export const JoyThemed = (props: { children?: ReactNode }): JSX.Element => {
  const { children } = props;
  return (
    <CssVarsProvider theme={theme}>
      <DarkMode>{children}</DarkMode>
    </CssVarsProvider>
  );
};
