import { useNavigate } from "react-router-dom";
import { MyEnvironmentComponent } from "./component";
import { useContext, useEffect, useState } from "react";
import { AwsConnectionContainer } from "./aws_connection/container";
import { GitConnectionsContainer } from "./git_connections/container";
import { FullDomainNamesContainer } from "./full_domain_names/container";
import { EnvFeaturesContainer } from "./env_features/container";
import { EnvironmentsContext } from "../../contexts/environments/context";
import {
  StackStatus,
  useDeployMyEnvFeaturesStackMutation,
  useGetMyEnvFeaturesStackStatusLazyQuery,
  useGetMyGitConnectionsLazyQuery,
  useGetMyHostedZonesLazyQuery,
  useTestMyWaffleRoleQuery,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { AlertsContext } from "../../contexts/alerts/context";
import { AlertType } from "../../contexts/alerts/type";

export const MyEnvironmentContainer = (props: { deploymentId: string }) => {
  const { deploymentId } = props;

  const { loading: envLoading, editDeployment } =
    useContext(EnvironmentsContext);
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const navigate = useNavigate();

  const navigateToHelp = (stepId: string) => {
    navigate(`/my_environment/${deploymentId}/help/${stepId}`, {
      replace: true,
    });
  };

  const [awsConnectionWorks, setAwsConnectionWorks] = useState<boolean>(false);
  const [gitConnectionExists, setGitConnectionExists] =
    useState<boolean>(false);
  const [domainNamesExists, setDomainNamesExists] = useState<boolean>(false);
  const [envFeaturesStackStatus, setEnvFeaturesStackStatus] =
    useState<StackStatus | null>(null);

  const {
    data: testMyWaffleRoleData,
    loading: testMyWaffleRoleLoading,
    refetch: refetchTestMyWaffleRole,
  } = useTestMyWaffleRoleQuery({
    client: bgwService.getClient(),
    variables: { deploymentId },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyGitConnections,
    { data: getMyGitConnectionsData, loading: getMyGitConnectionsLoading },
  ] = useGetMyGitConnectionsLazyQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyHostedZones,
    { data: getMyHostedZonesData, loading: getMyHostedZonesLoading },
  ] = useGetMyHostedZonesLazyQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyEnvFeaturesStackStatus,
    {
      data: getMyEnvFeaturesStackStatusData,
      loading: getMyEnvFeaturesStackStatusLoading,
      refetch: refetchGetMyEnvFeaturesStackStatus,
    },
  ] = useGetMyEnvFeaturesStackStatusLazyQuery({
    client: bgwService.getClient(),
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [envDeploymentLoading, setEnvDeploymentLoading] =
    useState<boolean>(false);

  const [
    deployMyEnvFeaturesStack,
    { loading: deployMyEnvFeaturesStackLoading },
  ] = useDeployMyEnvFeaturesStackMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      setEnvDeploymentLoading(false);
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const pollEnvFeaturesStackStatus = (
    deploymentId: string,
    callback: null | (() => void) = null
  ) => {
    const pollInterval = 5000; // poll every 5 seconds
    const poll = async () => {
      try {
        const { data } = await refetchGetMyEnvFeaturesStackStatus({
          deploymentId,
        });
        const status = data?.getMyEnvFeaturesStackStatus;
        // Update your state with the latest status
        setEnvFeaturesStackStatus(() => status || null);
        if (status && status.status && status.status.includes("IN_PROGRESS")) {
          setTimeout(poll, pollInterval);
        } else {
          if (callback) {
            callback();
          }
        }
      } catch (error) {
        // If there's an error, you might want to try again
        setTimeout(poll, pollInterval);
      }
    };
    poll();
  };

  const updateEnvFeaturesStack = (
    deploymentId: string,
    fullDomainName: string,
    emailNotificationList: string,
    logRetentionDays: number,
    restApiV1Enabled: boolean,
    restApiV1Subdomain: string,
    httpApiV2Enabled: boolean,
    httpApiV2Subdomain: string,
    vpcEnabled: boolean,
    vpcCidr: string,
    primaryPrivateCidr: string,
    secondaryPrivateCidr: string,
    primaryPublicCidr: string,
    secondaryPublicCidr: string,
    cognitoEnabled: boolean,
    cognitoUserPoolEnabled: boolean,
    cognitoAllowAdminCreateUserOnly: boolean,
    cognitoAlertsEnabled: boolean,
    cognitoOidcEnabled: boolean,
    cognitoOidcUrl: string,
    cognitoOidcClientId: string,
    cognitoOidcThumbprint: string,
    callback: null | (() => void) = null
  ) => {
    setEnvDeploymentLoading(true);
    deployMyEnvFeaturesStack({
      variables: {
        envFeaturesStackInput: {
          deploymentId,
          fullDomainName,
          emailNotificationList,
          logRetentionDays,
          restApiV1Enabled,
          restApiV1Subdomain,
          httpApiV2Enabled,
          httpApiV2Subdomain,
          vpcEnabled,
          vpcCidr,
          primaryPrivateCidr,
          secondaryPrivateCidr,
          primaryPublicCidr,
          secondaryPublicCidr,
          cognitoEnabled,
          cognitoUserPoolEnabled,
          cognitoAllowAdminCreateUserOnly,
          cognitoAlertsEnabled,
          cognitoOidcEnabled,
          cognitoOidcUrl,
          cognitoOidcClientId,
          cognitoOidcThumbprint,
        },
      },
      onCompleted: () => {
        setEnvDeploymentLoading(false);
        pollEnvFeaturesStackStatus(deploymentId, callback);
      },
    });
  };

  const retestMyWaffleRole = async () => {
    await refetchTestMyWaffleRole();
  };

  useEffect(() => {
    if (testMyWaffleRoleData) {
      const passed = testMyWaffleRoleData.testMyWaffleRole?.passed || false;
      setAwsConnectionWorks(() => passed);
      if (passed) {
        getMyGitConnections({
          variables: {
            deploymentId,
          },
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            setGitConnectionExists(
              (prev) => (data.getMyGitConnections?.length || 0) > 0
            );
          },
        });
        getMyHostedZones({
          variables: {
            deploymentId,
          },
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            setDomainNamesExists(
              (prev) => (data.getMyHostedZones?.length || 0) > 0
            );
          },
        });
        getMyEnvFeaturesStackStatus({
          variables: {
            deploymentId,
          },
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            setEnvFeaturesStackStatus(
              (prev) => data.getMyEnvFeaturesStackStatus || null
            );
          },
        });
      } else {
        setGitConnectionExists(false);
        setDomainNamesExists(false);
        setEnvFeaturesStackStatus(null);
      }
    }
  }, [
    testMyWaffleRoleData,
    deploymentId,
    getMyGitConnections,
    getMyHostedZones,
    getMyEnvFeaturesStackStatus,
  ]);

  useEffect(() => {
    setGitConnectionExists(
      (prev) => (getMyGitConnectionsData?.getMyGitConnections?.length || 0) > 0
    );
  }, [getMyGitConnectionsData]);

  useEffect(() => {
    setDomainNamesExists(
      (prev) => (getMyHostedZonesData?.getMyHostedZones?.length || 0) > 0
    );
  }, [getMyHostedZonesData]);

  useEffect(() => {
    setEnvFeaturesStackStatus(
      (prev) =>
        getMyEnvFeaturesStackStatusData?.getMyEnvFeaturesStackStatus || null
    );
  }, [getMyEnvFeaturesStackStatusData]);

  const awsConnectionComponent = (
    <AwsConnectionContainer
      deploymentId={deploymentId}
      navigateToHelp={navigateToHelp}
      awsConnectionWorks={awsConnectionWorks}
      refetchTestMyWaffleRole={retestMyWaffleRole}
    />
  );
  const gitConnectionsComponent = (
    <GitConnectionsContainer
      deploymentId={deploymentId}
      navigateToHelp={navigateToHelp}
    />
  );
  const domainNamesComponent = (
    <FullDomainNamesContainer
      deploymentId={deploymentId}
      navigateToHelp={navigateToHelp}
    />
  );
  const deployEnvironmentComponent = (
    <EnvFeaturesContainer
      deploymentId={deploymentId}
      navigateToHelp={navigateToHelp}
      envFeaturesStackStatus={envFeaturesStackStatus}
      updateEnvFeaturesStack={updateEnvFeaturesStack}
    />
  );

  const editDeploymentName = (name: string) => {
    editDeployment(deploymentId, name);
  };

  return (
    <MyEnvironmentComponent
      deploymentId={deploymentId}
      loading={
        envLoading ||
        testMyWaffleRoleLoading ||
        getMyGitConnectionsLoading ||
        getMyHostedZonesLoading ||
        getMyEnvFeaturesStackStatusLoading ||
        deployMyEnvFeaturesStackLoading ||
        envDeploymentLoading
      }
      awsConnectionWorks={awsConnectionWorks}
      gitConnectionExists={gitConnectionExists}
      domainNamesExists={domainNamesExists}
      envFeaturesStackStatus={envFeaturesStackStatus}
      awsConnectionComponent={awsConnectionComponent}
      gitConnectionsComponent={gitConnectionsComponent}
      domainNamesComponent={domainNamesComponent}
      deployEnvironmentComponent={deployEnvironmentComponent}
      editDeploymentName={editDeploymentName}
      navigateToHelp={navigateToHelp}
    />
  );
};
