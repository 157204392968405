import {
  Authenticator,
  Grid,
  Heading,
  Image,
  Text,
  ThemeProvider,
  Theme,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import waffle from "../../components/logos/waffle.svg";

export const AuthenticatorPage = () => {
  const { tokens } = useTheme();
  const theme: Theme = {
    name: "Auth Theme",
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 32px rgba(255,255,255,0.03)`,
            borderWidth: "1",
            borderColor: "rgba(255,255,255,0.06)",
            backgroundColor: tokens.colors.overlay["10"],
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: "rgb(255,209,80)",
            borderColor: "rgb(255,209,80)",
            color: "black",
            _hover: {
              backgroundColor: "rgb(255,209,80)",
              borderColor: "rgba(255,255,255,0.08)",
              color: "black",
            },
          },
          link: {
            color: "white",
            _hover: {
              backgroundColor: "rgba(255,255,255,0.08)",
              borderColor: "rgba(255,255,255,0.08)",
              color: "white",
            },
          },
        },
        fieldcontrol: {
          borderColor: "rgba(255,255,255,0.12)",
          _focus: {
            // boxShadow: `0 0 0 4px rgba(255,255,255,0.12)`,
            borderColor: "rgba(255,255,255,0.8)",
          },
          color: "white",
        },
        tabs: {
          item: {
            color: "rgba(255,255,255,0.5)",
            borderColor: tokens.colors.black,
            _active: {
              borderColor: tokens.colors.white,
              color: tokens.colors.white,
            },
            _hover: {
              color: tokens.colors.white,
            },
          },
        },
        heading: {
          color: tokens.colors.white,
        },
        text: {
          color: tokens.colors.black,
        },
      },
    },
  };

  return (
    <>
      <ThemeProvider theme={theme} colorMode="dark">
        <Grid
          templateColumns={{
            base: "1fr",
            // large: "1fr 1fr"
          }}
          templateRows="100%"
          gap={tokens.space.small}
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: "hidden",
            backgroundColor: "white",
          }}
        >
          <View padding="xxl" backgroundColor="rgba(0,0,0,0.94)">
            <Grid templateColumns="1fr" templateRows="64px 1fr 64px">
              <View textAlign="center">
                {/* <Heading level={6}>Welcome to Waffle!</Heading> */}
              </View>
              <View>
                <Authenticator loginMechanisms={["email"]} />
              </View>
              <View textAlign="center">
                <Grid
                  templateColumns="auto 64px 64px 164px auto"
                  templateRows="64px"
                  style={{
                    paddingTop: 64,
                  }}
                >
                  <View></View>
                  <View textAlign="right">
                    <Image alt="Waffle" src={waffle} height="50%" width="50%" />
                  </View>
                  <View
                    textAlign="left"
                    style={{ paddingTop: 2, paddingLeft: 4 }}
                  >
                    <Heading level={6}>Waffle</Heading>
                  </View>
                  <View
                    textAlign="left"
                    style={{ paddingTop: 9, paddingLeft: 4 }}
                  >
                    <Text
                      style={{
                        color: "rgba(255,255,255,0.5)",
                        fontSize: 8,
                        fontWeight: 100,
                      }}
                    >
                      © 2025 All Rights Reserved
                    </Text>
                  </View>
                </Grid>
              </View>
            </Grid>
          </View>
          {/* <View>
            <iframe
              src="https://www.wafflesoc2.com/"
              title="What is Waffle?"
              // frameBorder="0"
              style={{
                border: "0px solid transparent",
                overflow: "hidden",
                height: "100%",
                width: "100%",
              }}
              height="100%"
              width="100%"
            />
          </View> */}
        </Grid>
      </ThemeProvider>
    </>
  );
};
