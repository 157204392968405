import { useContext, useMemo, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { DeploymentStepsSettingsComponent } from "./component";
import {
  useEditMyDeploymentMutation,
  useGetMyDeploymentsQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { gql } from "@apollo/client";

export const DeploymentStepsSettingsContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);
  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const [
    editDeploymentMutation,
    {
      loading: editDeploymentMutationLoading,
      error: editDeploymentMutationError,
    },
  ] = useEditMyDeploymentMutation({
    client: bgwService.getClient(),
  });

  const [editDeploymentError, setEditDeploymentError] = useState<string | null>(
    null
  );

  const resetEditDeploymentError = () => {
    setEditDeploymentError(null);
  };

  const editDeployment = (
    id: string,
    name: string,
    accountId: string,
    awsRegion: string,
    defaultLogRetentionDays: number,
    defaultAlarmsEnabled: boolean,
    defaultDatabaseRetention: number,
    defaultRequireCicdManualApproval: boolean
  ) => {
    editDeploymentMutation({
      variables: {
        deploymentInput: {
          id,
          name,
          // accountId,
          // awsRegion,
          // defaultLogRetentionDays,
          // defaultAlarmsEnabled,
          // defaultDatabaseRetention,
          // defaultRequireCicdManualApproval,
          // fullDomainName: "",
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            getMyDeployments(existingDeployments = []) {
              const updatedDeploymentRef = cache.writeFragment({
                data: data?.editMyDeployment,
                fragment: gql`
                  fragment UpdatedDeployment on Deployment {
                    id
                    name
                    awsRegion
                    accountId
                    defaultLogRetentionDays
                    defaultAlarmsEnabled
                    defaultDatabaseRetention
                    defaultRequireCicdManualApproval
                    fullDomainName
                  }
                `,
              });
              return existingDeployments.map((d: { id: string }) =>
                d.id === id ? updatedDeploymentRef : d
              );
            },
          },
        });
      },
      refetchQueries: ["GetMyDeployments"],
      onError: (error) => {
        setEditDeploymentError(`Failed to update ${deploymentId}.`);
        console.error(error);
      },
    });
  };

  if (getMyDeploymentsError) {
    console.error(getMyDeploymentsError);
  }
  if (editDeploymentMutationError) {
    console.error(editDeploymentMutationError);
  }

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  const done: boolean = useMemo(() => {
    return ![
      deployment?.name !== null && deployment?.name !== undefined,
      deployment?.accountId !== null && deployment?.accountId !== undefined,
      deployment?.awsRegion !== null && deployment?.awsRegion !== undefined,
      deployment?.defaultLogRetentionDays !== null &&
        deployment?.defaultLogRetentionDays !== undefined,
      deployment?.defaultAlarmsEnabled !== null &&
        deployment?.defaultAlarmsEnabled !== undefined,
      // deployment?.defaultDatabaseRetention !== null &&
      //   deployment?.defaultDatabaseRetention !== undefined,
      deployment?.defaultRequireCicdManualApproval !== null &&
        deployment?.defaultRequireCicdManualApproval !== undefined,
    ].includes(false);
  }, [deployment]);

  return (
    <DeploymentStepsSettingsComponent
      loading={getMyDeploymentsLoading || editDeploymentMutationLoading}
      deployment={deployment || null}
      editDeployment={editDeployment}
      editDeploymentError={editDeploymentError}
      resetEditDeploymentError={resetEditDeploymentError}
      locked={false}
      done={done}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
    />
  );
};
