import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import { useEffect, useRef, useState } from "react";
import { LogRetentionDays } from "../../../configs/const";
import { PageLoading } from "../../../components/page_loading";
import { HostedZone } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import Checkbox from "@mui/joy/Checkbox";
import Switch from "@mui/joy/Switch";

export const EnvFeaturesComponent = (props: {
  loading: boolean;
  navigateToHelp: null | (() => void);
  deploymentId: string;
  envFeaturesParamsJson: string | null;
  envFeaturesStatus: string | null;
  hostedZones: HostedZone[];
  updateEnvFeaturesStack: (
    deploymentId: string,
    fullDomainName: string,
    emailNotificationList: string,
    logRetentionDays: number,
    restApiV1Enabled: boolean,
    restApiV1Subdomain: string,
    httpApiV2Enabled: boolean,
    httpApiV2Subdomain: string,
    vpcEnabled: boolean,
    vpcCidr: string,
    primaryPrivateCidr: string,
    secondaryPrivateCidr: string,
    primaryPublicCidr: string,
    secondaryPublicCidr: string,
    cognitoEnabled: boolean,
    cognitoUserPoolEnabled: boolean,
    cognitoAllowAdminCreateUserOnly: boolean,
    cognitoAlertsEnabled: boolean,
    cognitoOidcEnabled: boolean,
    cognitoOidcUrl: string,
    cognitoOidcClientId: string,
    cognitoOidcThumbprint: string,
    callBack: null | (() => void)
  ) => void;
  myEmail: string;
}) => {
  const {
    loading,
    navigateToHelp,
    envFeaturesParamsJson,
    envFeaturesStatus,
    hostedZones,
    deploymentId,
    updateEnvFeaturesStack,
    myEmail,
  } = props;

  const [emailNotificationList, setEmailNotificationList] =
    useState<string>(myEmail);
  const [fullDomainName, setFullDomainName] = useState<string>("");
  const [logRetentionDays, setLogRetentionDays] = useState<number>(365);
  const [restApiV1Enabled, setRestApiV1Enabled] = useState<boolean>(false);
  const [restApiV1Subdomain, setRestApiV1Subdomain] =
    useState<string>("restapi");
  const [httpApiV2Enabled, setHttpApiV2Enabled] = useState<boolean>(false);
  const [httpApiV2Subdomain, setHttpApiV2Subdomain] =
    useState<string>("httpapi");
  const [vpcEnabled, setVpcEnabled] = useState<boolean>(false);
  const [vpcCidr, setVpcCidr] = useState<string>("");
  const [primaryPrivateCidr, setPrimaryPrivateCidr] = useState<string>("");
  const [secondaryPrivateCidr, setSecondaryPrivateCidr] = useState<string>("");
  const [primaryPublicCidr, setPrimaryPublicCidr] = useState<string>("");
  const [secondaryPublicCidr, setSecondaryPublicCidr] = useState<string>("");
  const [cognitoEnabled, setCognitoEnabled] = useState<boolean>(false);
  const [cognitoUserPoolEnabled, setCognitoUserPoolEnabled] =
    useState<boolean>(false);
  const [cognitoAllowAdminCreateUserOnly, setCognitoAllowAdminCreateUserOnly] =
    useState<boolean>(false);
  const [cognitoAlertsEnabled, setCognitoAlertsEnabled] =
    useState<boolean>(false);
  const [cognitoOidcEnabled, setCognitoOidcEnabled] = useState<boolean>(false);
  const [cognitoOidcUrl, setCognitoOidcUrl] = useState<string>("");
  const [cognitoOidcClientId, setCognitoOidcClientId] = useState<string>("");
  const [cognitoOidcThumbprint, setCognitoOidcThumbprint] =
    useState<string>("");

  const [error, setError] = useState<boolean>(false);
  const [showAdvancedSettings, setShowAdvancedSettings] =
    useState<boolean>(false);

  useEffect(() => {
    if (!envFeaturesParamsJson) return;
    try {
      const envFeaturesParams = JSON.parse(envFeaturesParamsJson);
      setFullDomainName(
        envFeaturesParams.FullDomainName
          ? `${envFeaturesParams.FullDomainName}.`
          : ""
      );
      setEmailNotificationList(envFeaturesParams.EmailNotificationList || "");
      setLogRetentionDays(parseInt(envFeaturesParams.LogRetentionDays) || 365);
      setRestApiV1Enabled(envFeaturesParams.RestApiV1Enabled === "True");
      setRestApiV1Subdomain(envFeaturesParams.RestApiV1Subdomain || "");
      setHttpApiV2Enabled(envFeaturesParams.HttpApiV2Enabled === "True");
      setHttpApiV2Subdomain(envFeaturesParams.HttpApiV2Subdomain);
      setVpcEnabled(envFeaturesParams.VpcEnabled === "True");
      setVpcCidr(envFeaturesParams.VPCCidr);
      setPrimaryPrivateCidr(envFeaturesParams.PrimaryPrivateCidr);
      setSecondaryPrivateCidr(envFeaturesParams.SecondaryPrivateCidr);
      setPrimaryPublicCidr(envFeaturesParams.PrimaryPublicCidr);
      setSecondaryPublicCidr(envFeaturesParams.SecondaryPublicCidr);
      setCognitoEnabled(envFeaturesParams.CognitoEnabled === "True");
      setCognitoUserPoolEnabled(
        envFeaturesParams.CognitoUserPoolEnabled === "True"
      );
      setCognitoAllowAdminCreateUserOnly(
        envFeaturesParams.CognitoAllowAdminCreateUserOnly === "True"
      );
      setCognitoAlertsEnabled(
        envFeaturesParams.CognitoAlertsEnabled === "True"
      );
      setCognitoOidcEnabled(envFeaturesParams.CognitoOidcEnabled === "True");
      setCognitoOidcUrl(envFeaturesParams.CognitoOidcUrl);
      setCognitoOidcClientId(envFeaturesParams.CognitoOidcClientId);
      setCognitoOidcThumbprint(envFeaturesParams.CognitoOidcThumbprint);
      setError(false);
    } catch (error) {
      setError(true);
      console.error("Error parsing envFeaturesParamsJson", error);
    }
  }, [envFeaturesParamsJson]);

  const logRetentionDaysInputRef = useRef<HTMLInputElement | null>(null);
  const validLogRetentionDays = LogRetentionDays.includes(logRetentionDays);

  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="body-sm" sx={{ py: 1 }}>
            Waffle sets up the environment using AWS CloudFormation.{" "}
            <Link onClick={() => navigateToHelp && navigateToHelp()}>
              Read more
            </Link>
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Select domain name</FormLabel>
            <Select value={fullDomainName}>
              <Option
                value={""}
                onClick={() => {
                  setFullDomainName("");
                }}
              >
                AWS-generated domain- and hostnames
              </Option>
              {hostedZones.map((hostedZone) => (
                <Option
                  key={hostedZone.domainName}
                  value={hostedZone.domainName}
                  onClick={() => {
                    setFullDomainName(hostedZone.domainName);
                  }}
                >
                  {hostedZone.domainName}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              You can register custom domain names above.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Emails for alerts</FormLabel>
            <Input
              value={emailNotificationList}
              disabled={loading || error}
              onChange={(event) => {
                setEmailNotificationList(event.target.value);
              }}
            />
            <FormHelperText>
              Comma separated list of emails to send alerts to.
            </FormHelperText>
          </FormControl>
        </Grid>

        {envFeaturesParamsJson !== null && (
          <>
            <Grid xs={12}>
              <Checkbox
                checked={showAdvancedSettings}
                onChange={(event) => {
                  setShowAdvancedSettings(event.target.checked);
                }}
                label="Show advanced settings"
                size="sm"
                variant="outlined"
              />
            </Grid>
            {showAdvancedSettings && (
              <>
                <Grid xs={12} sm={restApiV1Enabled ? 6 : 12}>
                  <FormControl>
                    <FormLabel>Rest API V1</FormLabel>
                    <Typography
                      component="label"
                      startDecorator={
                        <Switch
                          checked={restApiV1Enabled}
                          onChange={(event) =>
                            setRestApiV1Enabled(event.target.checked)
                          }
                        />
                      }
                    >
                      {restApiV1Enabled ? "Enabled" : "Disabled"}
                    </Typography>
                    <FormHelperText>
                      The AWS ApiGateway V1 (REST) can be used for creating API
                      endpoints that require API Key or Cognito authentication.
                      It has a mandatory complex deployment process.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {restApiV1Enabled && (
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>Rest API V1 subdomain</FormLabel>
                      <Input
                        value={restApiV1Subdomain}
                        disabled={loading || error}
                        onChange={(event) => {
                          setRestApiV1Subdomain(event.target.value);
                        }}
                      />
                      <FormHelperText>
                        The host name for the REST API V1:{" "}
                        {`https://${restApiV1Subdomain}.${fullDomainName}`}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                )}
                <Grid xs={12} sm={httpApiV2Enabled ? 6 : 12}>
                  <FormControl>
                    <FormLabel>HTTP API V2</FormLabel>
                    <Typography
                      component="label"
                      startDecorator={
                        <Switch
                          checked={httpApiV2Enabled}
                          onChange={(event) =>
                            setHttpApiV2Enabled(event.target.checked)
                          }
                        />
                      }
                    >
                      {httpApiV2Enabled ? "Enabled" : "Disabled"}
                    </Typography>
                    <FormHelperText>
                      The AWS ApiGateway V2 (HTTP) can be used for creating API
                      endpoints with Cognito authentication.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {httpApiV2Enabled && (
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>HTTP API V2 subdomain</FormLabel>
                      <Input
                        value={httpApiV2Subdomain}
                        disabled={loading || error}
                        onChange={(event) => {
                          setHttpApiV2Subdomain(event.target.value);
                        }}
                      />
                      <FormHelperText>
                        The host name for the HTTP API V2:{" "}
                        {`https://${httpApiV2Subdomain}.${fullDomainName}`}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                )}
                <Grid xs={12} sm={vpcEnabled ? 6 : 12}>
                  <FormControl>
                    <FormLabel>VPC with private subnets</FormLabel>
                    <Typography
                      component="label"
                      startDecorator={
                        <Switch
                          checked={vpcEnabled}
                          onChange={(event) =>
                            setVpcEnabled(event.target.checked)
                          }
                        />
                      }
                    >
                      {vpcEnabled ? "Enabled" : "Disabled"}
                    </Typography>
                    <FormHelperText>
                      Can be used for hosting backend services privately and
                      securely with strong access control.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* {vpcEnabled && (
                  <>
                    <Grid xs={12} sm={6}>
                      <FormControl>
                        <FormLabel>VPC CIDR</FormLabel>
                        <Input
                          value={vpcCidr}
                          disabled={loading || error}
                          onChange={(event) => {
                            setVpcCidr(event.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <FormControl>
                        <FormLabel>Primary private subnet CIDR</FormLabel>
                        <Input
                          value={primaryPrivateCidr}
                          disabled={loading || error}
                          onChange={(event) => {
                            setPrimaryPrivateCidr(event.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <FormControl>
                        <FormLabel>Secondary private subnet CIDR</FormLabel>
                        <Input
                          value={secondaryPrivateCidr}
                          disabled={loading || error}
                          onChange={(event) => {
                            setSecondaryPrivateCidr(event.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <FormControl>
                        <FormLabel>Primary public subnet CIDR</FormLabel>
                        <Input
                          value={primaryPublicCidr}
                          disabled={loading || error}
                          onChange={(event) => {
                            setPrimaryPublicCidr(event.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <FormControl>
                        <FormLabel>Secondary public subnet CIDR</FormLabel>
                        <Input
                          value={secondaryPublicCidr}
                          disabled={loading || error}
                          onChange={(event) => {
                            setSecondaryPublicCidr(event.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )} */}
                <Grid xs={12} sm={cognitoEnabled ? 12 : 6}>
                  <FormControl>
                    <FormLabel>Cognito authentication</FormLabel>
                    <Typography
                      component="label"
                      startDecorator={
                        <Switch
                          checked={cognitoEnabled}
                          onChange={(event) =>
                            setCognitoEnabled(event.target.checked)
                          }
                        />
                      }
                    >
                      {cognitoEnabled ? "Enabled" : "Disabled"}
                    </Typography>
                    <FormHelperText>
                      Use IAM authenciation on your backends and/or Cognito for
                      authenticating users of frontend applications.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {cognitoEnabled && (
                  <>
                    <Grid xs={12} sm={cognitoUserPoolEnabled ? 12 : 6}>
                      <FormControl>
                        <FormLabel>Cognito user pool</FormLabel>
                        <Typography
                          component="label"
                          startDecorator={
                            <Switch
                              checked={cognitoUserPoolEnabled}
                              onChange={(event) =>
                                setCognitoUserPoolEnabled(event.target.checked)
                              }
                            />
                          }
                        >
                          {cognitoUserPoolEnabled ? "Enabled" : "Disabled"}
                        </Typography>
                        <FormHelperText>
                          Create a Cognito User Pool for handling
                          authentication.
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {cognitoUserPoolEnabled && (
                      <>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>Only admin can create users</FormLabel>
                            <Typography
                              component="label"
                              startDecorator={
                                <Switch
                                  checked={cognitoAllowAdminCreateUserOnly}
                                  onChange={(event) =>
                                    setCognitoAllowAdminCreateUserOnly(
                                      event.target.checked
                                    )
                                  }
                                />
                              }
                            >
                              {cognitoAllowAdminCreateUserOnly
                                ? "Enabled"
                                : "Disabled"}
                            </Typography>
                            <FormHelperText>
                              No self-service user creation.
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>Authentication notifications</FormLabel>
                            <Typography
                              component="label"
                              startDecorator={
                                <Switch
                                  checked={cognitoAlertsEnabled}
                                  onChange={(event) =>
                                    setCognitoAlertsEnabled(
                                      event.target.checked
                                    )
                                  }
                                />
                              }
                            >
                              {cognitoAlertsEnabled ? "Enabled" : "Disabled"}
                            </Typography>
                            <FormHelperText>
                              Get an alert when a user is created.
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid xs={12} sm={cognitoOidcEnabled ? 12 : 6}>
                      <FormControl>
                        <FormLabel>OIDC authentication</FormLabel>
                        <Typography
                          component="label"
                          startDecorator={
                            <Switch
                              checked={cognitoOidcEnabled}
                              onChange={(event) =>
                                setCognitoOidcEnabled(event.target.checked)
                              }
                            />
                          }
                        >
                          {cognitoOidcEnabled ? "Enabled" : "Disabled"}
                        </Typography>
                        <FormHelperText>
                          Select this if you want to integrate with a 3rd party
                          OIDC provider.
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {cognitoOidcEnabled && (
                      <>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>OIDC URL</FormLabel>
                            <Input
                              value={cognitoOidcUrl}
                              disabled={loading || error}
                              onChange={(event) => {
                                setCognitoOidcUrl(event.target.value);
                              }}
                            />
                          </FormControl>
                          <FormHelperText>
                            Your OIDC client URL at the provider.
                          </FormHelperText>
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>OIDC Client ID</FormLabel>
                            <Input
                              value={cognitoOidcClientId}
                              disabled={loading || error}
                              onChange={(event) => {
                                setCognitoOidcClientId(event.target.value);
                              }}
                            />
                          </FormControl>
                          <FormHelperText>Your OIDC client ID.</FormHelperText>
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>OIDC Thumbprint</FormLabel>
                            <Input
                              value={cognitoOidcThumbprint}
                              disabled={loading || error}
                              onChange={(event) => {
                                setCognitoOidcThumbprint(event.target.value);
                              }}
                            />
                          </FormControl>
                          <FormHelperText>
                            Your OIDC thumbprint. If your provider does not
                            provide a thumbprint, you need to generate it.
                          </FormHelperText>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid xs={12}>
                  <FormControl>
                    <FormLabel>Log retention days</FormLabel>
                    <Input
                      type="number"
                      slotProps={{
                        input: {
                          ref: logRetentionDaysInputRef,
                          min: 0,
                          max: 365,
                          step: 1,
                        },
                      }}
                      value={logRetentionDays}
                      disabled={loading || error}
                      error={!validLogRetentionDays}
                      endDecorator={<Typography fontSize="xs">days</Typography>}
                      onChange={(event) => {
                        setLogRetentionDays(Number(event.target.value));
                      }}
                    />
                    <FormHelperText>
                      Allowed values: {LogRetentionDays.join(", ")}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            )}
          </>
        )}

        <Grid xs={12}>
          {envFeaturesStatus?.includes("IN_PROGRESS") ? (
            <Typography level="body-lg" sx={{ my: 2 }}>
              Environment is being deployed:{" "}
              <Typography
                variant="soft"
                color="warning"
                fontSize="xs"
                sx={{ borderRadius: "12px", mx: "4px", px: "8px" }}
              >
                {envFeaturesStatus}
              </Typography>
            </Typography>
          ) : (
            <Button
              disabled={
                loading || error || envFeaturesStatus?.includes("IN_PROGRESS")
              }
              onClick={() =>
                updateEnvFeaturesStack(
                  deploymentId,
                  fullDomainName,
                  emailNotificationList,
                  logRetentionDays,
                  restApiV1Enabled,
                  restApiV1Subdomain,
                  httpApiV2Enabled,
                  httpApiV2Subdomain,
                  vpcEnabled,
                  vpcCidr,
                  primaryPrivateCidr,
                  secondaryPrivateCidr,
                  primaryPublicCidr,
                  secondaryPublicCidr,
                  cognitoEnabled,
                  cognitoUserPoolEnabled,
                  cognitoAllowAdminCreateUserOnly,
                  cognitoAlertsEnabled,
                  cognitoOidcEnabled,
                  cognitoOidcUrl,
                  cognitoOidcClientId,
                  cognitoOidcThumbprint,
                  null
                )
              }
              sx={{ mt: 2 }}
            >
              {envFeaturesStatus ? "Update" : "Create"} Environment
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
