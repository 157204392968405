import { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { DrawerProvider } from "../../../contexts/drawer/provider";
import { LayoutContainer } from "./container";
import { Drawer } from "../drawer";
import { Header } from "./floating_header";

export const Layout = (props: { children?: ReactNode }) => {
  return (
    <DrawerProvider>
      <LayoutContainer drawerContent={Drawer} headerContent={Header} {...props}>
        <Outlet />
      </LayoutContainer>
    </DrawerProvider>
  );
};
