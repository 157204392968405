import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddMyEnvironmentComponent } from "./component";
import { EnvironmentsContext } from "../../../contexts/environments/context";
import { FeaturesContext } from "../../../contexts/features/context";

export const AddMyEnvironmentContainer = () => {
  const {
    loading: envLoading,
    addDeployment,
    deployments,
  } = useContext(EnvironmentsContext);
  const { singleDeployment } = useContext(FeaturesContext);
  const canAddDeployment = !singleDeployment || deployments.length === 0;

  const [addDeplomymentId, setAddDeploymentId] = useState<string>("");

  const navigate = useNavigate();

  const addNewDeployment = () => {
    addDeployment(addDeplomymentId, () => {
      navigate(`/my_environment/${addDeplomymentId}`, {
        replace: true,
      });
    });
  };

  return (
    <AddMyEnvironmentComponent
      loading={envLoading}
      deployments={deployments}
      addDeplomymentId={addDeplomymentId}
      setAddDeploymentId={setAddDeploymentId}
      addDeployment={addNewDeployment}
      canAddDeployment={canAddDeployment}
    />
  );
};
