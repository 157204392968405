import { useContext, useEffect, useState } from "react";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { AlertsContext } from "../../contexts/alerts/context";
import {
  useDeployMyAnyCfnStackMutation,
  useGetMyCfnStackIdsLazyQuery,
  useGetMyCfnStackTemplateLazyQuery,
  useGetMyCfnStackParametersLazyQuery,
  useGetMyDeploymentsQuery,
  useGetMyCfnStackStatusLazyQuery,
  useGetMyCfnStackLatestEventsLazyQuery,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../contexts/alerts/type";
import { MyAnyStackComponent } from "./component";

export const MyAnyStackContainer = (props: {}) => {
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const [selectedDeploymentId, setSelectedDeploymentId] = useState<
    string | null
  >(null);

  const { data: getMyDeploymentsData, loading: getMyDeploymentsLoading } =
    useGetMyDeploymentsQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const [getMyCfnStackIds, { loading: getMyCfnStackIdsLoading }] =
    useGetMyCfnStackIdsLazyQuery({
      client: bgwService.getClient(),
      fetchPolicy: "network-only",
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const [stackIds, setStackIds] = useState<string[]>([]);

  useEffect(() => {
    if (selectedDeploymentId) {
      getMyCfnStackIds({
        variables: { deploymentId: selectedDeploymentId },
        onCompleted: (data) => {
          setStackIds(data.getMyCfnStackIds);
        },
      });
    }
  }, [selectedDeploymentId, getMyCfnStackIds]);

  const [selectedStackId, setSelectedStackId] = useState<string | null>(null);

  const [stackTemplate, setStackTemplate] = useState<string>("");

  const [getMyCfnStackTemplate, { loading: getMyCfnStackTemplateLoading }] =
    useGetMyCfnStackTemplateLazyQuery({
      client: bgwService.getClient(),
      fetchPolicy: "network-only",
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  useEffect(() => {
    if (selectedDeploymentId && selectedStackId) {
      getMyCfnStackTemplate({
        variables: {
          deploymentId: selectedDeploymentId,
          stackId: selectedStackId,
        },
        onCompleted: (data) => {
          setStackTemplate(data.getMyCfnStackTemplate || "");
        },
      });
    }
  }, [selectedDeploymentId, selectedStackId, getMyCfnStackTemplate]);

  const [stackParameters, setStackParameters] = useState<string>("");

  const [getMyCfnStackParameters, { loading: getMyCfnStackParametersLoading }] =
    useGetMyCfnStackParametersLazyQuery({
      client: bgwService.getClient(),
      fetchPolicy: "network-only",
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  useEffect(() => {
    if (selectedDeploymentId && selectedStackId) {
      getMyCfnStackParameters({
        variables: {
          deploymentId: selectedDeploymentId,
          stackId: selectedStackId,
        },
        onCompleted: (data) => {
          setStackParameters(data.getMyCfnStackParameters || "");
        },
      });
    }
  }, [selectedDeploymentId, selectedStackId, getMyCfnStackParameters]);

  const [stackStatus, setStackStatus] = useState<string | null>(null);
  const [stackFullDetails, setStackFullDetails] = useState<string | null>(null);

  const [
    getMyCfnStackStatus,
    {
      loading: getMyCfnStackStatusLoading,
      refetch: getMyCfnStackStatusRefetch,
    },
  ] = useGetMyCfnStackStatusLazyQuery({
    client: bgwService.getClient(),
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
    onCompleted: (data) => {
      setStackStatus(data.getMyCfnStackStatus?.status || null);
      setStackFullDetails(data.getMyCfnStackStatus?.fullDetails || null);
    },
  });

  useEffect(() => {
    if (selectedDeploymentId && selectedStackId) {
      getMyCfnStackStatus({
        variables: {
          deploymentId: selectedDeploymentId,
          stackId: selectedStackId,
        },
      });
    }
  }, [selectedDeploymentId, selectedStackId, getMyCfnStackStatus]);

  const refreshStackStatus = () => {
    if (selectedDeploymentId && selectedStackId) {
      getMyCfnStackStatusRefetch({
        deploymentId: selectedDeploymentId,
        stackId: selectedStackId,
      });
    }
  };

  const [stackLatestEvents, setStackLatestEvents] = useState<string | null>(
    null
  );

  const [
    getMyCfnStackLatestEvents,
    { loading: getMyCfnStackLatestEventsLoading },
  ] = useGetMyCfnStackLatestEventsLazyQuery({
    client: bgwService.getClient(),
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  useEffect(() => {
    if (selectedDeploymentId && selectedStackId) {
      getMyCfnStackLatestEvents({
        variables: {
          deploymentId: selectedDeploymentId,
          stackId: selectedStackId,
        },
        onCompleted: (data) => {
          setStackLatestEvents(data.getMyCfnStackLatestEvents || null);
        },
      });
    }
  }, [selectedDeploymentId, selectedStackId, getMyCfnStackLatestEvents]);

  const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);

  const [deployMyAnyCfnStack, { loading: deployMyAnyCfnStackLoading }] =
    useDeployMyAnyCfnStackMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
      onCompleted: () => {
        setMutationInProgress(false);
      },
    });

  const deployStack = () => {
    if (
      selectedDeploymentId &&
      selectedStackId &&
      stackTemplate !== "" &&
      stackParameters !== ""
    ) {
      setMutationInProgress(true);
      deployMyAnyCfnStack({
        variables: {
          stackDeploymentInput: {
            deploymentId: selectedDeploymentId,
            id: selectedStackId,
            template: stackTemplate,
            parameters: stackParameters,
          },
        },
      });
    }
  };

  return (
    <MyAnyStackComponent
      loading={
        getMyDeploymentsLoading ||
        getMyCfnStackIdsLoading ||
        getMyCfnStackTemplateLoading ||
        getMyCfnStackParametersLoading ||
        getMyCfnStackStatusLoading ||
        getMyCfnStackLatestEventsLoading ||
        deployMyAnyCfnStackLoading ||
        mutationInProgress
      }
      deploymentIds={(getMyDeploymentsData?.getMyDeployments || []).map(
        (d) => d.id
      )}
      selectedDeploymentId={selectedDeploymentId}
      setSelectedDeploymentId={setSelectedDeploymentId}
      stackIds={stackIds}
      selectedStackId={selectedStackId}
      setSelectedStackId={setSelectedStackId}
      stackTemplate={stackTemplate}
      setStackTemplate={setStackTemplate}
      stackParameters={stackParameters}
      setStackParameters={setStackParameters}
      stackStatus={stackStatus}
      stackFullDetails={stackFullDetails}
      refreshStackStatus={refreshStackStatus}
      stackLatestEvents={stackLatestEvents}
      deployStack={deployStack}
    />
  );
};
